import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { APPLICATION_DATE_FORMAT } from '@app/_constants/dateFormat';
import { toYesNoString } from '@app/_helpers/helper';
import { InvestorListItemModel } from '@app/_models/investor';
import { AdminService } from '@app/_services/admin.service';
import { SchemeInvestorDto } from '@app/_services/dto/registration.dto';
import { DataDtoFilter, DataDtoRange } from '@app/_services/dto/response.dto';
import { FeaturePermissionService } from '@app/_services/feature-permission.service';
import { AddUpdateSchemeInvestorComponent } from '../add-update-scheme-investor';

@Component({
  selector: 'app-scheme-investors',
  templateUrl: './scheme-investors.component.html',
  styleUrls: ['./scheme-investors.component.scss']
})
export class SchemeInvestorsComponent implements OnInit, AfterViewInit {

  gridColumns: string[] = [];

  userColumns: string[] =
    [
      'investorId',
      'schemeId',
      'investorName',
      'identityPanNumber',
      'identifier',
      'folioNo',
      'schemeShortName',
      'schemeFullName',
      'fundName',
      'fundHouseName'
    ];

  investorDetailsColumns: string[] =
    [
      'email1',
      'email2',
      'email3',
      'email4',
      'email5',
      'isCarryClass',
      'class',
      'forfeitAndTransferDate',
      'salutation',
      'modeOfHolding',
      'taxPanNumber',
      'contactNo',
      'address',
      'address1',
      'address2',
      'city',
      'state',
      'pincode',
      'bankAccountType',
      'bankName',
      'ifscCode',
      'micrCode',
      'accountNumber',
      'taxStatus',
      'setupFees',
      'managementFees',
      'operatingExpenses',
      'sebiInvestorType1',
      'sebiInvestorType2',
      'sebiInvestorType3',
      'capitalCommitment',
      'capitalContribution',
      'kpfsRecordStatus',
      'kpfsIncompleteRecordRemark'
    ];

  gridDatasource: MatTableDataSource<SchemeInvestorDto>;
  discription: string[];
  selectedStatusName: string;
  public range: DataDtoRange = new DataDtoRange();
  public filter: DataDtoFilter = new DataDtoFilter();
  public loading: boolean = false;
  public tableDisplay: string = 'table';

  statusName: any = [{ id: 0, name: '' }];
  pdialogConfig: MatDialogConfig;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('empTbSort') empTbSort = new MatSort();

  constructor(private dialogModel: MatDialog,
    public readonly adminService: AdminService,
    public readonly featurePermissionService: FeaturePermissionService,
    private datePipe: DatePipe
  ) {

    this.range.limit = 10;
    this.gridColumns = [...this.userColumns];
    this.gridColumns.push('actions');
  }

  public async ngOnInit(): Promise<void> {
    this.loadInvestors();
  }

  ngAfterViewInit(): void {
    if (this.gridDatasource) {
      this.gridDatasource.paginator = this.paginator;
      this.gridDatasource.sort = this.empTbSort;
    }
  }

  public get APPLICATION_DATE_FORMAT() {
    return APPLICATION_DATE_FORMAT;
  }

  public async loadInvestors() {
    this.setLoading(true);
    this.adminService.getSchemeInvestors().then(response => {
      this.setLoading(false);
      if (response.isSuccess) {
        this.gridDatasource = new MatTableDataSource(response.data);
        this.gridDatasource.paginator = this.paginator;
        this.gridDatasource.sort = this.empTbSort;
        this.range.total = response.dataTotalCount;

        this.gridDatasource.sortingDataAccessor = (item, property) => {
          switch (property) {
            case 'identifier': return item.investor.identifier;
            case 'identityPanNumber': return item.investor.identityPanNumber;
            case 'fundName': return item.fundFullName;
            case 'fundHouseName': return item.fundHouseFullName;
            default: return item[property];
          }
        };

        this.gridDatasource.filterPredicate = (data, filter: string) => {
          const accumulator = (currentTerm, key) => {
            if (key === 'investor') {
              return currentTerm + data.investor.identifier + data.investor.identityPanNumber;
            }
            else if (key === 'isCarryClass') {
              return currentTerm + toYesNoString(data.isCarryClass);
            }
            else if (key === 'forfeitAndTransferDate') {
              return currentTerm + (this.datePipe.transform(data.forfeitAndTransferDate, APPLICATION_DATE_FORMAT));
            }

            return currentTerm + data[key];

          };
          const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
          const transformedFilter = filter.trim().toLowerCase();
          return dataStr.indexOf(transformedFilter) !== -1;
        };
      }
    });
  }

  public async onPage(event: PageEvent) {
    this.range.limit = event.pageSize;
    this.range.offset = event.pageIndex * event.pageSize;
    await this.loadInvestors();
  }

  getstatusName(statusId: number) {
    var data = this.statusName.filter((c: { id: number; }) => c.id == statusId);
    if (data.length > 0) {
      return data[0].name;
    }
    return null;
  }

  addUserDialog() {
    let dialogRef = this.dialogModel.open(AddUpdateSchemeInvestorComponent, {
      disableClose: false,
      //panelClass: 'fullscreen-dialog',
      width: '50%'
    });
    dialogRef.backdropClick().subscribe(_ => {
      dialogRef.close();
    })
    // When user close the dialog
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadInvestors();
      }
    });

  }

  openEditDialog(investor: InvestorListItemModel) {

    let dialogRef = this.dialogModel.open(AddUpdateSchemeInvestorComponent, {
      disableClose: false,
      width: '50%',

      data: investor
    });
    dialogRef.backdropClick().subscribe(_ => {
      dialogRef.close();
    })
    // When user close the dialog
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadInvestors();
      }
    });

  }

  applyFilter(event: Event) {
    console.log('1');
    const filterValue = (event.target as HTMLInputElement).value;
    this.gridDatasource.filter = filterValue.trim().toLowerCase();

    if (this.gridDatasource.paginator) {
      this.gridDatasource.paginator.firstPage();
    }
  }

  toggleColumnsDisplay(e: any) {
    if (e.checked) {
      this.tableDisplay = 'block';
      this.gridColumns = [...this.userColumns, ... this.investorDetailsColumns];
    }
    else {
      this.tableDisplay = 'table';
      this.gridColumns = [...this.userColumns];
    }
    this.gridColumns.push('actions');
  }

  private setLoading(isLoading: boolean) {
    this.loading = isLoading;
    if (isLoading) {
      this.gridDatasource = new MatTableDataSource([]);
    }
  }
}
