import { DtoBase } from "./base.dto";
import { FundHouseDto } from "./fund-house.dto";
import { SchemeDto } from "./scheme.dto";

export class RegisterUserDto extends DtoBase<number> {
  public fullName: string = '';
  public email: string = '';
}

export class AddFundHouseAdminDto extends RegisterUserDto {
  public fundHouseId: number;
}

export class AddFundAdminDto extends RegisterUserDto {
  public fundId: number;
}

export class UpdateFundHouseAdminDto {
  public fullName: string = '';
  public email: string = '';
  public isActive: boolean = false;
}

export class UpdateFundAdminDto {
  public fullName: string = '';
  public email: string = '';
  public isActive: boolean = false;
}

export class InvestorDto extends DtoBase<number> {
  public fullName: string = '';
  public identityPanNumber: string;
  public identifier: string;
  public isActive: boolean;

  public fundHouseId: number;
  public fundHouse: FundHouseDto;
}

export class AddUpdateInvestorDto extends DtoBase<number> {
  public fullName: string = '';
  public identityPanNumber: string;
  public identifier: string;
  public isActive: boolean;

  public fundHouseId: number;

}

export class SchemeInvestorBaseDto extends DtoBase<number> {
  public folioNo: string;
  public isCarryClass: boolean;
  public class: string;
  public forfeitAndTransferDate: Date;
  public salutation: string;
  public modeOfHolding: number;
  public taxPanNumber: string;
  public contactNo: string;
  public address: string;
  public address1: string;
  public address2: string;
  public city: string;
  public state: string;
  public pincode: string;
  public bankAccountType: string;
  public bankName: string;
  public ifscCode: string;
  public micrCode: string;
  public accountNumber: string;
  public taxStatus: string;
  public setupFees: string;
  public managementFees: string;
  public operatingExpenses: string;
  public sebiInvestorType1: string;
  public sebiInvestorType2: string;
  public sebiInvestorType3: string;
  public capitalCommitment: number;
  public capitalContribution: number;
  public kpfsRecordStatus: string;
  public kpfsIncompleteRecordRemark: string;

  public email1: string;
  public email2?: string = null;
  public email3?: string = null;
  public email4?: string = null;
  public email5?: string = null;

  public schemeId: number;
  public investorId: number;
}


export class SchemeInvestorDto extends SchemeInvestorBaseDto {

  public fundHouseShortName: string;
  public fundHouseFullName: string;
  public schemeShortName: string;
  public schemeFullName: string;
  public fundShortName: string;
  public fundFullName: string;
  public investorName: string;

  public fundHouseId: number;
  public fundId: number;

  public scheme: SchemeDto;
  public investor: InvestorDto;
}

export class AddUpdateSchemeInvestorDto extends SchemeInvestorBaseDto {
}