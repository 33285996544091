<div class="dialog-custom-content-wrap dialog-custom-box">
  <div>
    <div class="mt-3">
      <div class="d-flex align-items-center justify-content-between bg-white py-2 px-3">
        <h2 class="dialog-custom-header-text m-0">Profile</h2>
      </div>

      <div class="d-flex align-items-center justify-content-between bg-white py-2 px-3"
        style="margin-top: 20px;padding-bottom: 20px !important;width: 100%;">
        <div fxLayout="column" style="width: 100%;">
          <div class="mt-3">
            <div class="container">
              <div class="row" *ngIf="!localStorageService.isInvestor">
                <div class="col">Full Name</div>
                <div class="col">: {{localStorageService.currentUser.fullName}}</div>
              </div>
              <div class="row">
                <div class="col">Role</div>
                <div class="col">: {{localStorageService.currentUser.role}}</div>
              </div>
              <div class="row" *ngIf="localStorageService.isFundAdmin">
                <div class="col">Fund</div>
                <div class="col">: {{localStorageService.currentUser.fund.shortName}}</div>
              </div>
              <div class="row" *ngIf="localStorageService.isFundAdmin || localStorageService.isFundHouseAdmin">
                <div class="col">Fund House</div>
                <div class="col">: {{localStorageService.currentUser.fundHouse.shortName}}</div>
              </div>
              <div class="row">
                <div class="col">Email</div>
                <div class="col">: {{localStorageService.currentUser.email}}</div>
              </div>
            </div>
            <table mat-table [dataSource]="gridDatasource" class="body-text" *ngIf="localStorageService.isInvestor"
              style="width: 100%;margin-top:20px;">
              <ng-container matColumnDef="investor">
                <th mat-header-cell *matHeaderCellDef class="column-5 fw-semibold"> Investor </th>
                <td mat-cell *matCellDef="let item;let i = index" [attr.rowspan]="item.schemeCount"
                  [style.display]="item.partitionIndex===0?'':'none'">
                  {{item?.investor}}
                </td>
              </ng-container>
              <ng-container matColumnDef="fundHouse">
                <th mat-header-cell *matHeaderCellDef class="column-5  fw-semibold"> Fund House </th>
                <td mat-cell *matCellDef="let item">{{item?.fundHouse}}</td>
              </ng-container>
              <ng-container matColumnDef="fund">
                <th mat-header-cell *matHeaderCellDef class="column-5  fw-semibold"> Fund </th>
                <td mat-cell *matCellDef="let item">{{item?.fund}}</td>
              </ng-container>
              <ng-container matColumnDef="schemeShortName">
                <th mat-header-cell *matHeaderCellDef class="column-5  fw-semibold"> Scheme Short Name</th>
                <td mat-cell *matCellDef="let item">{{item?.schemeFullName}}</td>
              </ng-container>
              <ng-container matColumnDef="schemeFullName">
                <th mat-header-cell *matHeaderCellDef class="column-5  fw-semibold"> Scheme Full Name</th>
                <td mat-cell *matCellDef="let item">{{item?.schemeShortName}}</td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="gridColumns">
              </tr>
              <tr mat-row *matRowDef="let row; columns: gridColumns;">
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>