import { Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';

import { environment } from '@environments/environment';
import { RoutePaths } from './_constants';
import { AuthenticationService } from './_services';
import { CurrentUserDto } from './_services/dto/user.dto';
import { LocalStorageService } from './_services/local-storage.service';
@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
    user?: CurrentUserDto | null;
    constructor(
        public authenticationService: AuthenticationService,
        public localStorageService: LocalStorageService) {
        this.authenticationService.user.subscribe(x => this.user = x);

        console.log(environment.environmentType);
    }

    public get environment() {
        return environment;
    }

    public get routePaths() {
        return RoutePaths;
    }

    logout() {
        this.authenticationService.logout();
    }

    ngOnInit(): void {
        this.getScreenSize();
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        setTimeout(() => {
            const screenHeight = window.innerHeight;
            const header = document.getElementById('main-header')?.clientHeight;
            const footer = document.getElementById('footer')?.clientHeight;
            const finalHeight = screenHeight - (header + footer);
            const mainContainer = document.getElementById('main-container');
            if (mainContainer && !isNaN(finalHeight)) {
                mainContainer.style.height = finalHeight + "px";
            }
        }, 500);
    }
}