import { Component } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { LocalStorageService } from './_services/local-storage.service';
@Component({
    selector: 'app-base',
    template: `No template`
})
export class AppBaseComponent {
    constructor(
        private activatedRoute: ActivatedRoute,
        public readonly localStorageService: LocalStorageService
    ) {
        const documentTypesResponse = this.activatedRoute.snapshot.data['documentTypesResponse'];
        const fundHousesResponse = this.activatedRoute.snapshot.data['fundHousesResponse'];
        const masterDataResponse = this.activatedRoute.snapshot.data['masterDataResponse'];

        if (!localStorageService.documentTypes) {
            localStorage.setItem('documentTypes', JSON.stringify(documentTypesResponse.data));
        }

        if (!localStorageService.fundHouses) {
            localStorage.setItem('fundHouses', JSON.stringify(fundHousesResponse.data));
        }

        if (!localStorageService.masterData && masterDataResponse?.data) {
            localStorage.setItem('masterData', JSON.stringify(masterDataResponse.data));
        }
    }
}