import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { toActiveInActiveString, toYesNoString } from '@app/_helpers/helper';
import { FundHouseAdminListItemModel } from '@app/_models/user';
import { AdminService } from '@app/_services/admin.service';
import { DataDtoFilter, DataDtoRange } from '@app/_services/dto/response.dto';
import { AddUpdateFundHouseAdminComponent } from '../add-update-fund-house-admin';

@Component({
  selector: 'app-fund-house-admins',
  templateUrl: './fund-house-admins.component.html',
  styleUrls: ['./fund-house-admins.component.scss']
})
export class FundHouseAdminsComponent implements OnInit, AfterViewInit {

  gridColumns: string[] = ['fullName', 'email', 'fundHouseName', 'emailConfirmedStatus', 'activeStatus', 'actions'];
  gridDatasource: MatTableDataSource<FundHouseAdminListItemModel>;
  discription: string[];
  selectedStatusName: string;
  public range: DataDtoRange = new DataDtoRange();
  public filter: DataDtoFilter = new DataDtoFilter();
  public loading: boolean = false;

  statusName: any = [{ id: 0, name: '' }];
  pdialogConfig: MatDialogConfig;
  dialogWithForm: MatDialogRef<AddUpdateFundHouseAdminComponent>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('empTbSort') empTbSort = new MatSort();

  constructor(private dialogModel: MatDialog,
    public readonly adminService: AdminService
  ) {

    this.range.limit = 10;
  }

  public async ngOnInit(): Promise<void> {
    this.loadFundHouseAdmins();
  }

  ngAfterViewInit(): void {
    if (this.gridDatasource) {
      this.gridDatasource.paginator = this.paginator;
      this.gridDatasource.sort = this.empTbSort;
    }
  }

  public async loadFundHouseAdmins() {
    this.setLoading(true);
    this.adminService.getAllFundHouseAdmins().then(response => {
      this.setLoading(false);
      if (response.isSuccess) {

        var listItems = response.data.map(x => {
          return {
            id: x.id,
            fundHouseId: x.fundHouseId,
            email: x.email,
            emailConfirmed: x.emailConfirmed,
            isActive: x.isActive,
            fullName: x.fullName,
            fundHouseName: x.fundHouseName,
            activeStatus: toActiveInActiveString(x.isActive),
            emailConfirmedStatus: toYesNoString(x.emailConfirmed)
          } as FundHouseAdminListItemModel;
        });
        this.gridDatasource = new MatTableDataSource(listItems);
        this.gridDatasource.paginator = this.paginator;
        this.gridDatasource.sort = this.empTbSort;
        this.range.total = response.dataTotalCount;
      }
    });
  }

  public async onPage(event: PageEvent) {
    this.range.limit = event.pageSize;
    this.range.offset = event.pageIndex * event.pageSize;
    await this.loadFundHouseAdmins();
  }

  getstatusName(statusId: number) {
    var data = this.statusName.filter((c: { id: number; }) => c.id == statusId);
    if (data.length > 0) {
      return data[0].name;
    }
    return null;
  }

  simpleDialog: MatDialogRef<AddUpdateFundHouseAdminComponent>;

  addUserDialog() {
    let dialogRef = this.dialogModel.open(AddUpdateFundHouseAdminComponent, {
      disableClose: false,
      //panelClass: 'fullscreen-dialog',
      width: '50%',
    });
    dialogRef.backdropClick().subscribe(_ => {
      dialogRef.close();
    })
    // When user close the dialog
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadFundHouseAdmins();
      }
    });

  }

  openEditDialog(fundHouseAdmin: FundHouseAdminListItemModel) {

    let dialogRef = this.dialogModel.open(AddUpdateFundHouseAdminComponent, {
      disableClose: false,
      width: '50%',
      data: fundHouseAdmin
    });
    dialogRef.backdropClick().subscribe(_ => {
      dialogRef.close();
    })
    // When user close the dialog
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadFundHouseAdmins();
      }
    });

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.gridDatasource.filter = filterValue.trim().toLowerCase();

    if (this.gridDatasource.paginator) {
      this.gridDatasource.paginator.firstPage();
    }
  }

  private setLoading(isLoading: boolean) {
    this.loading = isLoading;
    if (isLoading) {
      this.gridDatasource = new MatTableDataSource([]);
    }
  }
}
