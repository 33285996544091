import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { AdminService } from '@app/_services/admin.service';
import { CommonService } from '@app/_services/common.service';
import { FundHouseDto } from '@app/_services/dto/fund-house.dto';
import { DataDtoFilter, DataDtoRange } from '@app/_services/dto/response.dto';
import { LocalStorageService } from '@app/_services/local-storage.service';
import { AppBaseComponent } from '@app/app-base.component';
import { AddUpdateFundHouseComponent } from '../add-update-fund-house';

@Component({
  selector: 'app-fund-houses',
  templateUrl: './fund-houses.component.html',
  styleUrls: ['./fund-houses.component.scss']
})
export class FundHousesComponent extends AppBaseComponent implements OnInit, AfterViewInit {

  gridColumns: string[] = ['id', 'shortName', 'fullName', 'logo', 'actions'];
  gridDatasource: MatTableDataSource<FundHouseDto>;
  public loading: boolean = false;
  public range: DataDtoRange = new DataDtoRange();
  public filter: DataDtoFilter = new DataDtoFilter();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('gridSort') gridSort = new MatSort();

  constructor(private dialogModel: MatDialog,
    public readonly adminService: AdminService,
    public readonly commonService: CommonService,
    localStorageService: LocalStorageService,
    activatedRoute: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    super(activatedRoute, localStorageService);
    this.range.limit = 10;
  }

  public async ngOnInit(): Promise<void> {
    this.loadFundHouses();
  }

  ngAfterViewInit(): void {
    if (this.gridDatasource) {
      this.gridDatasource.paginator = this.paginator;
      this.gridDatasource.sort = this.gridSort;
    }
  }

  public async loadFundHouses() {
    this.setLoading(true);
    await this.commonService.getAllFundHouses().then(response => {
      this.setLoading(false);
      if (response.isSuccess) {
        localStorage.setItem('fundHouses', JSON.stringify(response.data));

        this.gridDatasource = new MatTableDataSource(this.localStorageService.fundHouses);
        this.gridDatasource.paginator = this.paginator;
        this.gridDatasource.sort = this.gridSort;
        this.range.total = this.localStorageService.fundHouses.length;
      }
    });
  }

  public async onPage(event: PageEvent) {
    this.range.limit = event.pageSize;
    this.range.offset = event.pageIndex * event.pageSize;
    await this.loadFundHouses();
  }

  openAddDialog() {
    let dialogRef = this.dialogModel.open(AddUpdateFundHouseComponent, {
      disableClose: false,
      //panelClass: 'fullscreen-dialog',
      width: '50%'
    });
    dialogRef.backdropClick().subscribe(_ => {
      dialogRef.close();
    })
    // When user close the dialog
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadFundHouses();
      }
    });

  }


  openEditDialog(fundHouse: FundHouseDto) {
    let dialogRef = this.dialogModel.open(AddUpdateFundHouseComponent, {
      disableClose: false,
      width: '50%',
      data: fundHouse
    });
    dialogRef.backdropClick().subscribe(_ => {
      dialogRef.close();
    })
    // When user close the dialog
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadFundHouses();
      }
    });

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.gridDatasource.filter = filterValue.trim().toLowerCase();

    if (this.gridDatasource.paginator) {
      this.gridDatasource.paginator.firstPage();
    }
  }

  private setLoading(isLoading: boolean) {
    this.loading = isLoading;
    if (isLoading) {
      this.gridDatasource = new MatTableDataSource([]);
    }
  }
}
