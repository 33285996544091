<div class="dialog-custom-content-wrap dialog-custom-box">
  <div>
    <div class="mt-3">
      <div class="d-flex align-items-center justify-content-between bg-white py-2 px-3">
        <h2 class="dialog-custom-header-text m-0">Fund Admins</h2>
        <div class="d-flex align-items-center gap-3">
          <div class="user-grid-head">
            <mat-form-field appearance="outline">
              <mat-label>Filter</mat-label>
              <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Email" #input>
            </mat-form-field>
          </div>
          <button mat-raised-button color="primary" class="me-3 w-100 rounded-pill btn-ht" (click)="addUserDialog()">
            <mat-icon class="material-icons-outlined">person</mat-icon>
            Add Fund Admin</button>
        </div>
      </div>

      <mat-card-content style="max-height: 400px;overflow: auto;">
        <div fxLayout="column">
          <table mat-table [dataSource]="gridDatasource" class="body-text" matSort #empTbSort="matSort">
            <ng-container matColumnDef="fullName">
              <th mat-header-cell *matHeaderCellDef class="column-5 fw-semibold" mat-sort-header> Full Name </th>
              <td mat-cell *matCellDef="let item">
                {{item?.fullName}}
              </td>
            </ng-container>

            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef class="column-5  fw-semibold" mat-sort-header> Email </th>
              <td mat-cell *matCellDef="let item">
                {{item?.email}}
              </td>
            </ng-container>
            <ng-container matColumnDef="fundHouseFullName">
              <th mat-header-cell *matHeaderCellDef class="column-5 fw-semibold" mat-sort-header> Fund House </th>
              <td mat-cell *matCellDef="let item">
                {{item?.fundHouseName}}
              </td>
            </ng-container>
            <ng-container matColumnDef="fundFullName">
              <th mat-header-cell *matHeaderCellDef class="column-5 fw-semibold" mat-sort-header> Fund </th>
              <td mat-cell *matCellDef="let item">
                {{item?.fundName}}
              </td>
            </ng-container>
            <ng-container matColumnDef="emailConfirmedStatus">
              <th mat-header-cell *matHeaderCellDef class="column-5 fw-semibold" mat-sort-header> Email Confirmed </th>
              <td mat-cell *matCellDef="let item">
                {{item.emailConfirmedStatus}}
              </td>
            </ng-container>

            <ng-container matColumnDef="activeStatus">
              <th mat-header-cell *matHeaderCellDef class="column-5 fw-semibold" mat-sort-header> Status </th>
              <td mat-cell *matCellDef="let item">
                {{item.activeStatus}}
              </td>
            </ng-container>
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef class="column-5  fw-semibold"> </th>
              <td mat-cell *matCellDef="let item">
                <button mat-icon-button matTooltip="Click to Edit" class="iconbutton" color="primary">
                  <mat-icon aria-label="Edit" (click)="openEditDialog(item)">edit</mat-icon>
                </button>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="gridColumns">
            </tr>
            <tr mat-row *matRowDef="let row; columns: gridColumns;">
            </tr>
            <tr class="mat-row no-data-row" *matNoDataRow>
              <td *ngIf="loading" class="mat-cell-load" [attr.colspan]="gridColumns.length">
                <div class="spinner">
                  <mat-spinner></mat-spinner>
                </div>
              </td>
              <td *ngIf="!loading" style="text-align: center;" class="mat-cell" [attr.colspan]="gridColumns.length">
                No data matching the filter.
              </td>
            </tr>
          </table>
          <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="25"
            aria-label="Select page of users"></mat-paginator>
        </div>
      </mat-card-content>
    </div>

  </div>
</div>