import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { FundAdminListItemModel } from '@app/_models/user';
import { AlertsService } from '@app/_services/alerts.service';
import { CommonService } from '@app/_services/common.service';
import { FundHouseDto } from '@app/_services/dto/fund-house.dto';
import { FundDto } from '@app/_services/dto/fund.dto';
import { AddFundAdminDto, UpdateFundAdminDto } from '@app/_services/dto/registration.dto';
import { LocalStorageService } from '@app/_services/local-storage.service';
import { RegistrationService } from '@app/_services/registration.service';
import { AppBaseComponent } from '@app/app-base.component';
import { first } from 'rxjs';

@Component({
  selector: 'app-add-update-fund-admin',
  templateUrl: 'add-update-fund-admin.component.html',
  styleUrls: ['add-update-fund-admin.component.scss']
})
export class AddUpdateFundAdminComponent extends AppBaseComponent implements OnInit {

  form!: FormGroup;
  addUserDto: AddFundAdminDto = new AddFundAdminDto();
  submitted = false;
  loading = false;
  error = '';
  fundHouses: FundHouseDto[] = [];
  funds: FundDto[] = [];
  constructor(
    public dialogRef: MatDialogRef<AddUpdateFundAdminComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FundAdminListItemModel,
    private fb: FormBuilder,
    private alertsService: AlertsService,
    private readonly registrationService: RegistrationService,
    private readonly commonService: CommonService,
    localStorageService: LocalStorageService,
    activatedRoute: ActivatedRoute
  ) {

    super(activatedRoute, localStorageService);
    dialogRef.disableClose = false;
    dialogRef.backdropClick().subscribe(_ => {
      dialogRef.close();
    })
  }

  ngOnInit() {

    this.form = this.fb.group({
      id: [null],
      fullName: ['', Validators.required],
      email: ['', this.isUpdate ? [] : [Validators.required, Validators.email]],
      fundHouseId: ['', Validators.required],
      fundId: ['', Validators.required],
      isActive: ['', this.isUpdate ? [Validators.required] : []]
    }
    );

    if (this.isUpdate) {
      this.form.controls.id.setValue(this.data.id);
      this.form.controls.fullName.setValue(this.data.fullName);
      this.form.controls.email.setValue(this.data.email);
      this.form.controls.fundHouseId.setValue(this.data.fundHouseId);
      this.form.controls.fundId.setValue(this.data.fundId);
      this.form.controls.isActive.setValue(this.data.isActive);

      var selectedFund = new FundDto();
      selectedFund.id = this.data.fundId;
      selectedFund.fullName = this.data.fundName;

      this.funds.push(selectedFund);
    }

    this.fundHouses = this.localStorageService.fundHouses;
  }

  get registerFormControl() {
    return this.form.controls;
  }

  public get isUpdate(): boolean {
    return (this.data && this.data.id != null);
  }

  onSubmit() {
    if (this.form.valid) {
      if (this.isUpdate) {
        this.updateFundAdmin();
      }
      else {
        this.addNewFundAdmin();
      }

    }
  }

  private addNewFundAdmin() {
    this.addUserDto = new AddFundAdminDto();
    this.addUserDto.fullName = this.registerFormControl.fullName.value;
    this.addUserDto.email = this.registerFormControl.email.value;
    this.addUserDto.fundId = this.registerFormControl.fundId.value;

    this.loading = true;
    this.registrationService.addFundAdmin(this.addUserDto)
      .pipe(first())
      .subscribe({
        next: (response) => {
          this.submitted = false;
          this.loading = false;
          if (response.isSuccess) {
            this.alertsService.showInfo("User added and confirmation link has been sent to the email.", "Message", "");
            this.dialogRef.close(true);
          }
          else
            this.alertsService.showInfo(response.message);
        },
        error: error => {
          this.loading = false;
          error = error.message;
        }
      });
  }

  private updateFundAdmin() {
    let fundAdmin = new UpdateFundAdminDto();
    fundAdmin.fullName = this.registerFormControl.fullName.value;
    fundAdmin.email = this.registerFormControl.email.value;
    fundAdmin.isActive = this.registerFormControl.isActive.value;

    this.loading = true;
    this.registrationService.updateFundAdmin(fundAdmin)
      .pipe(first())
      .subscribe({
        next: (response) => {
          this.submitted = false;
          this.loading = false;
          if (response.isSuccess) {
            this.alertsService.showInfo("User updated successfully!.", "Message", "");
            this.dialogRef.close(true);
          }
          else
            this.alertsService.showInfo(response.message);
        },
        error: error => {
          this.loading = false;
          error = error.message;
        }
      });
  }

  public onFundHouseChange(e: any) {
    this.commonService.getAllFunds(e.value).then(response => {
      if (response.isSuccess) {
        this.funds = response.data;
      }
    });
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }
}
