<h2 mat-dialog-title>{{isUpdate?'Update':'Add'}} Fund</h2>

<form class="form" [formGroup]="form" (ngSubmit)="onSubmit()">
    <mat-dialog-content>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Fund House</mat-label>
            <mat-select [disabled]="isUpdate" formControlName="fundHouseId">
                <mat-option *ngFor="let fh of fundHouses" [value]="fh.id">
                    {{fh.fullName}}
                </mat-option>
            </mat-select>
            <mat-error
                *ngIf="(formControl.fundHouseId.touched || submitted) && formControl.fundHouseId.errors?.required">
                Fund house is required
            </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Short Name</mat-label>
            <input type="text" matInput formControlName="shortName" placeholder="Short Name">
            <mat-error *ngIf="(formControl.shortName.touched || submitted) && formControl.shortName.errors?.required">
                Short Name is required
            </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Full Name</mat-label>
            <input type="text" matInput formControlName="fullName" placeholder="Full Name">
            <mat-error *ngIf="(formControl.fullName.touched || submitted) && formControl.fullName.errors?.required">
                Full Name is required
            </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>SEBI Reg. No.</mat-label>
            <input type="text" matInput formControlName="sebiRegistrationNumber" placeholder="SEBI Reg. No.">
            <mat-error
                *ngIf="(formControl.sebiRegistrationNumber.touched || submitted) && formControl.sebiRegistrationNumber.errors?.required">
                SEBI Reg. No. is required
            </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Investment Mngr.Name</mat-label>
            <input type="text" matInput formControlName="investmentManagerName" placeholder="Investment Mngr.Name">
            <mat-error
                *ngIf="(formControl.investmentManagerName.touched || submitted) && formControl.investmentManagerName.errors?.required">
                Investment Mngr.Name is required
            </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Sponser Name</mat-label>
            <input type="text" matInput formControlName="sponserName" placeholder="Sponser Name">
            <mat-error
                *ngIf="(formControl.sponserName.touched || submitted) && formControl.sponserName.errors?.required">
                Sponser Name is required
            </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Merchant Banker Name</mat-label>
            <input type="text" matInput formControlName="merchantBankerName" placeholder="Merchant Banker Name">
            <mat-error
                *ngIf="(formControl.merchantBankerName.touched || submitted) && formControl.merchantBankerName.errors?.required">
                Merchant Banker Name is required
            </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Legal Advisor Name</mat-label>
            <input type="text" matInput formControlName="legalAdvisorName" placeholder="Legal Advisor Name">
            <mat-error
                *ngIf="(formControl.legalAdvisorName.touched || submitted) && formControl.legalAdvisorName.errors?.required">
                Legal Advisor Name is required
            </mat-error>
        </mat-form-field>
        <div class="my-3 text-center">

        </div>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button [disabled]="loading" type="submit" mat-raised-button color="primary"
            class="me-3 w-40 rounded-pill btn-ht">
            <span *ngIf="loading" class="spinner-border spinner-border-sm me-1"></span>
            {{isUpdate?'Update':'Submit'}}</button>
        <button type="button" mat-raised-button color="warn" class="me-3 w-40 rounded-pill btn-ht"
            (click)="onNoClick()">Close</button></mat-dialog-actions>
</form>