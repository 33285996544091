<h2 mat-dialog-title>{{isUpdate?'Update':'Add'}} Document</h2>

<form class="form" [formGroup]="form" (ngSubmit)="onSubmit()">
    <mat-dialog-content>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Fund House</mat-label>
            <mat-select [disabled]="isUpdate" formControlName="fundHouseId"
                (selectionChange)="onFundHouseChange($event)">
                <mat-option *ngFor="let fh of fundHouses" [value]="fh.id">
                    {{fh.fullName}}
                </mat-option>
            </mat-select>
            <mat-error
                *ngIf="(formControls.fundHouseId.touched || submitted) && formControls.fundHouseId.errors?.required">
                Fund house is required
            </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Document Type</mat-label>
            <mat-select formControlName="documentTypeId">
                <mat-option *ngFor="let d of documentTypes" [value]="d.id">
                    {{d.name}}
                </mat-option>
            </mat-select>
            <mat-error
                *ngIf="(formControls.documentTypeId.touched || submitted) && formControls.documentTypeId.errors?.required">
                Document Type is required
            </mat-error>
        </mat-form-field>
        <!-- <mat-form-field class="date-mat-field" [hidden]="!isDrawdownReference" appearance="outline">
            <mat-label>Notice Date</mat-label>
            <div class="date_container">
                <input type="text" matInput [matDatepicker]="picker" formControlName="noticeDate">
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </div>
        </mat-form-field>

        <mat-form-field class="date-mat-field" [hidden]="!isDrawdownReference" appearance="outline">
            <mat-label>Due Date</mat-label>
            <div class="date_container">
                <input type="text" matInput [matDatepicker]="dueDatePicker" formControlName="dueDate">
                <mat-datepicker-toggle matIconSuffix [for]="dueDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #dueDatePicker></mat-datepicker>
            </div>
        </mat-form-field>
        <mat-form-field class="date-mat-field" [hidden]="!isStatementOfAccount" appearance="outline">
            <mat-label>Statement As On Date</mat-label>
            <div class="date_container">
                <input type="text" matInput [matDatepicker]="statementAsOnDatePicker"
                    formControlName="statementAsOnDate">
                <mat-datepicker-toggle matIconSuffix [for]="statementAsOnDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #statementAsOnDatePicker></mat-datepicker>
            </div>
        </mat-form-field>
        <mat-form-field class="date-mat-field" [hidden]="!isStatementOfAccount" appearance="outline">
            <mat-label>Statement Issue Date</mat-label>
            <div class="date_container">
                <input type="text" matInput [matDatepicker]="statementIssueDatePicker"
                    formControlName="statementIssueDate">
                <mat-datepicker-toggle matIconSuffix [for]="statementIssueDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #statementIssueDatePicker></mat-datepicker>
            </div>
        </mat-form-field>
        <mat-form-field class="date-mat-field" [hidden]="!isDistribution" appearance="outline">
            <mat-label>Distribution Date</mat-label>
            <div class="date_container">
                <input type="text" matInput [matDatepicker]="distributionDatePicker" formControlName="distributionDate">
                <mat-datepicker-toggle matIconSuffix [for]="distributionDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #distributionDatePicker></mat-datepicker>
            </div>
        </mat-form-field>
        <mat-form-field class="date-mat-field" [hidden]="!isNewsletter" appearance="outline">
            <mat-label>Date</mat-label>
            <div class="date_container">
                <input type="text" matInput [matDatepicker]="newsletterDatePicker" formControlName="newsletterDate">
                <mat-datepicker-toggle matIconSuffix [for]="newsletterDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #newsletterDatePicker></mat-datepicker>
            </div>
        </mat-form-field>
        <mat-form-field [hidden]="!isAdvanceTax" class="w-100" appearance="outline">
            <mat-label>Financial Year</mat-label>
            <input type="text" matInput formControlName="financialYear" placeholder="Financial Year">
        </mat-form-field>
        <mat-form-field class="date-mat-field" [hidden]="!isAdvanceTax" appearance="outline">
            <mat-label>Date</mat-label>
            <div class="date_container">
                <input type="text" matInput [matDatepicker]="advanceTaxDatePicker" formControlName="advanceTaxDate">
                <mat-datepicker-toggle matIconSuffix [for]="advanceTaxDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #advanceTaxDatePicker></mat-datepicker>
            </div>
        </mat-form-field> -->
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>File</mat-label>
            <button type="button" mat-icon-button matPrefix
                (click)="fileInput.click()"><mat-icon>attach_file</mat-icon></button>
            <input type="text" readonly matInput formControlName="fileName">
            <input hidden (change)="onFileSelected($event)" #fileInput type="file" [multiple]="!isUpdate">
            <mat-error *ngIf="(formControls.fileName.touched || submitted) && formControls.fileName.errors?.required">
                File is required
            </mat-error>
        </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button [disabled]="loading" type="submit" mat-raised-button color="primary"
            class="me-3 w-40 rounded-pill btn-ht">
            <span *ngIf="loading" class="spinner-border spinner-border-sm me-1"></span>
            {{isUpdate?'Update':'Submit'}}</button>
        <button type="button" mat-raised-button color="warn" class="me-3 w-40 rounded-pill btn-ht"
            (click)="onNoClick()">Close</button>
    </mat-dialog-actions>
</form>