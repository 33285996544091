import { Injectable } from '@angular/core';
import { Role } from '@app/_models';
import { AdminService } from './admin.service';
import { CommonService } from './common.service';
import { DocumentTypeDto } from './dto/document-type.dto';
import { FundHouseDto } from './dto/fund-house.dto';
import { MasterDataDto } from './dto/master-data.dto';
import { CurrentUserDto } from './dto/user.dto';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
    constructor(private readonly commonService: CommonService, private readonly adminService: AdminService) {
    }

    public get currentUser(): CurrentUserDto {
        return JSON.parse(localStorage.getItem('user'));
    }

    public get fundHouses(): FundHouseDto[] {
        return JSON.parse(localStorage.getItem('fundHouses'));
    }

    public get documentTypes(): DocumentTypeDto[] {
        return JSON.parse(localStorage.getItem('documentTypes'));
    }

    public get masterData(): MasterDataDto {
        return JSON.parse(localStorage.getItem('masterData'));
    }

    public get isAdmin(): boolean {
        return this.currentUser?.role === Role.Admin;
    }

    public get isFundHouseAdmin(): boolean {
        return this.currentUser?.role === Role.FundHouseAdmin;
    }

    public get isFundAdmin(): boolean {
        return this.currentUser?.role === Role.FundAdmin;
    }

    public get isInvestor(): boolean {
        return this.currentUser?.role === Role.Investor;
    }
}