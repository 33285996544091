import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { FundListItemModel } from '@app/_models/fund';
import { CommonService } from '@app/_services/common.service';
import { FundDto } from '@app/_services/dto/fund.dto';
import { DataDtoFilter, DataDtoRange } from '@app/_services/dto/response.dto';
import { FeaturePermissionService } from '@app/_services/feature-permission.service';
import { LocalStorageService } from '@app/_services/local-storage.service';
import { AppBaseComponent } from '@app/app-base.component';
import { AddUpdateFundComponent } from '../add-update-fund';

@Component({
  selector: 'app-funds',
  templateUrl: './funds.component.html',
  styleUrls: ['./funds.component.scss']
})
export class FundsComponent extends AppBaseComponent implements OnInit, AfterViewInit {

  gridColumns: string[] = ['shortName', 'fullName', 'fundHouseName', 'sebiRegistrationNumber', 'investmentManagerName', 'sponserName', 'merchantBankerName', 'legalAdvisorName', 'actions'];
  gridDatasource: MatTableDataSource<FundListItemModel>;
  public range: DataDtoRange = new DataDtoRange();
  public filter: DataDtoFilter = new DataDtoFilter();
  public loading: boolean = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('gridSort') gridSort = new MatSort();

  constructor(private dialogModel: MatDialog,
    public readonly commonService: CommonService,
    public readonly featurePermission: FeaturePermissionService,
    localStorageService: LocalStorageService,
    activatedRoute: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    super(activatedRoute, localStorageService);
    this.range.limit = 10;
  }

  public async ngOnInit(): Promise<void> {
    await this.loadFunds();
  }

  ngAfterViewInit(): void {
    if (this.gridDatasource) {
      this.gridDatasource.paginator = this.paginator;
      this.gridDatasource.sort = this.gridSort;
    }
  }

  public async loadFunds() {
    this.setLoading(true);
    this.commonService.getAllFunds().then(response => {
      this.setLoading(false);
      if (response.isSuccess) {
        var fundModels = response.data.map(fund => {
          let fundModel = new FundListItemModel();
          fundModel.id = fund.id;
          fundModel.fullName = fund.fullName;
          fundModel.shortName = fund.shortName;
          fundModel.fundHouseName = fund.fundHouse.fullName;
          fundModel.fundHouseId = fund.fundHouseId;
          fundModel.investmentManagerName = fund.investmentManagerName;
          fundModel.legalAdvisorName = fund.legalAdvisorName;
          fundModel.merchantBankerName = fund.merchantBankerName;
          fundModel.sponserName = fund.sponserName;
          fundModel.sebiRegistrationNumber = fund.sebiRegistrationNumber;

          return fundModel;
        });

        this.gridDatasource = new MatTableDataSource(fundModels);
        this.gridDatasource.paginator = this.paginator;
        this.gridDatasource.sort = this.gridSort;
        this.range.total = response.dataTotalCount;
      }
    });
  }

  public async onPage(event: PageEvent) {
    this.range.limit = event.pageSize;
    this.range.offset = event.pageIndex * event.pageSize;
    await this.loadFunds();
  }

  openAddDialog() {
    let dialogRef = this.dialogModel.open(AddUpdateFundComponent, {
      disableClose: false,
      //panelClass: 'fullscreen-dialog',
      width: '50%',
    });
    dialogRef.backdropClick().subscribe(_ => {
      dialogRef.close();
    })
    // When user close the dialog
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadFunds();
      }
    });

  }


  openEditDialog(fund: FundDto) {
    let dialogRef = this.dialogModel.open(AddUpdateFundComponent, {
      disableClose: false,
      width: '50%',
      data: fund
    });
    dialogRef.backdropClick().subscribe(_ => {
      dialogRef.close();
    })
    // When user close the dialog
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadFunds();
      }
    });

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.gridDatasource.filter = filterValue.trim().toLowerCase();

    if (this.gridDatasource.paginator) {
      this.gridDatasource.paginator.firstPage();
    }
  }

  private setLoading(isLoading: boolean) {
    this.loading = isLoading;
    if (isLoading) {
      this.gridDatasource = new MatTableDataSource([]);
    }
  }
}
