<div class="container p-0 d-block position-fixed top-0" *ngIf="user">
  <!-- nav -->
  <nav class="navbar navbar-expand d-block navbar-dark bg-dark gray-text py-4 float-start side-navbar" *ngIf="user">
    <div class="px-4">
      <div class="d-flex align-items-center">
        <mat-icon fontIcon="computer" class="white-text"></mat-icon>
        <div class="white-text fs-5 fw-semibold ms-3 text-uppercase">
          KPFS Exp
        </div>
      </div>
      <div class="gray-text mt-2">Dashboard</div>
    </div>
    <div class="">
      <div class="text-uppercase py-3 px-4">Main Menu</div>
    </div>
    <div class="navbar-nav flex-column">
      <div class="py-2 px-4 links d-flex align-items-center" *ngIf="localStorageService.isAdmin">
        <mat-icon class="material-icons-outlined">home</mat-icon>
        <a class="nav-item nav-link" [routerLink]="routePaths.fundHouses" routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }">Fund Houses</a>
      </div>
      <div class="py-2 px-4 links d-flex align-items-center"
        *ngIf="localStorageService.isAdmin || localStorageService.isFundHouseAdmin">
        <mat-icon class="material-icons-outlined">money</mat-icon>
        <a class="nav-item nav-link" [routerLink]="routePaths.funds" routerLinkActive="active">Funds</a>
      </div>
      <div class="py-2 px-4 links d-flex align-items-center"
        *ngIf="localStorageService.isAdmin || localStorageService.isFundHouseAdmin || localStorageService.isFundAdmin">
        <mat-icon class="material-icons-outlined">money</mat-icon>
        <a class="nav-item nav-link" [routerLink]="routePaths.schemes" routerLinkActive="active">Schemes</a>
      </div>
      <div class="py-2 px-4 links d-flex align-items-center" *ngIf="localStorageService.isAdmin">
        <mat-icon class="material-icons-outlined">person</mat-icon>
        <a class="nav-item nav-link" [routerLink]="routePaths.fundHouseAdmins" routerLinkActive="active">Fund House
          Admins</a>
      </div>
      <div class="py-2 px-4 links d-flex align-items-center" *ngIf="localStorageService.isAdmin">
        <mat-icon class="material-icons-outlined">person</mat-icon>
        <a class="nav-item nav-link" [routerLink]="routePaths.fundAdmins" routerLinkActive="active">Fund Admins</a>
      </div>
      <div class="py-2 px-4 links d-flex align-items-center"
        *ngIf="localStorageService.isAdmin  || localStorageService.isFundHouseAdmin || localStorageService.isFundAdmin">
        <mat-icon class="material-icons-outlined">person</mat-icon>
        <a class="nav-item nav-link" [routerLink]="routePaths.investors" routerLinkActive="active">Investors</a>
      </div>
      <div class="py-2 px-4 links d-flex align-items-center"
        *ngIf="localStorageService.isAdmin  || localStorageService.isFundHouseAdmin">
        <mat-icon class="material-icons-outlined">person</mat-icon>
        <a class="nav-item nav-link" [routerLink]="routePaths.schemeInvestors" routerLinkActive="active">Scheme
          Investors</a>
      </div>
      <div class="py-2 px-4 links d-flex align-items-center">
        <mat-icon class="material-icons-outlined">folder</mat-icon>
        <a class="nav-item nav-link" [routerLink]="routePaths.documents" routerLinkActive="active">Documents</a>
      </div>
    </div>
  </nav>
  <div class="header float-start">
    <div class="row align-items-center py-2 px-3" id="main-header">
      <div class="col-md-5 mx-auto">
        <h2 class="m-0 d-flex align-items-center">
          Welcome Back, {{ user.fullName }}
          <mat-icon class="ms-2 material-icons-outlined">handshake</mat-icon>
          <img style="margin-left: 20px;" *ngIf="user.fundHouse" width="100" height="40"
            src="{{user.fundHouse.logoImagePath}}">
        </h2>
      </div>
      <div *ngIf="user" class="col-md-7 d-flex justify-content-between align-items-center">
        <div class="header-profile d-flex align-items-center gap-3">
          <img src="assets/face16.jpg" alt="face" class="rounded-circle" />
          {{ user.fullName }}
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon fontIcon="arrow_drop_down"></mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <a mat-menu-item [routerLink]="routePaths.profile">Profile</a>
            <button mat-menu-item (click)="logout()">Log Out</button>
          </mat-menu>
        </div>
      </div>
    </div>
    <div class="container p-2 inner-container" id="main-container">
      <router-outlet></router-outlet>
    </div>
    <div id="footer">
    </div>
  </div>
</div>
<div *ngIf="!user">
  <router-outlet></router-outlet>
</div>