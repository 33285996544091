import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { APPLICATION_DATE_FORMAT } from '@app/_constants/dateFormat';
import { SchemeListItemModel } from '@app/_models/scheme';
import { CommonService } from '@app/_services/common.service';
import { DataDtoFilter, DataDtoRange } from '@app/_services/dto/response.dto';
import { FeaturePermissionService } from '@app/_services/feature-permission.service';
import { LocalStorageService } from '@app/_services/local-storage.service';
import { AppBaseComponent } from '@app/app-base.component';
import { AddUpdateSchemeComponent } from '../add-update-scheme';

@Component({
  selector: 'app-schemes',
  templateUrl: './schemes.component.html',
  styleUrls: ['./schemes.component.scss']
})
export class SchemesComponent extends AppBaseComponent implements OnInit, AfterViewInit {

  gridColumns: string[] = [
    'id',
    'shortName',
    'fullName',
    'fundFullName',
    'fundHouseFullName',
    'schemeManager',
    'launchDate',
    'date1',
    'date2',
    'date3',
    'date4',
    'date5',
    'date6',
    'actions'
  ];
  gridDatasource: MatTableDataSource<SchemeListItemModel>;
  public range: DataDtoRange = new DataDtoRange();
  public filter: DataDtoFilter = new DataDtoFilter();
  public loading: boolean = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('gridSort') gridSort = new MatSort();

  constructor(private dialogModel: MatDialog,
    public readonly commonService: CommonService,
    public readonly featurePermission: FeaturePermissionService,
    localStorageService: LocalStorageService,
    activatedRoute: ActivatedRoute,
    private readonly datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    super(activatedRoute, localStorageService);
    this.range.limit = 10;
  }

  public async ngOnInit(): Promise<void> {
    await this.loadFunds();
  }

  ngAfterViewInit(): void {
    if (this.gridDatasource) {
      this.gridDatasource.paginator = this.paginator;
      this.gridDatasource.sort = this.gridSort;
    }
  }

  public get APPLICATION_DATE_FORMAT() {
    return APPLICATION_DATE_FORMAT;
  }

  public async loadFunds() {
    this.setLoading(true);
    this.commonService.getAllSchemes().then(response => {
      this.setLoading(false);
      if (response.isSuccess) {
        var schemeModels = response.data.map(scheme => {
          let schemeModel = new SchemeListItemModel();
          schemeModel.id = scheme.id;
          schemeModel.fundId = scheme.fundId;
          schemeModel.fundHouseId = scheme.fund.fundHouseId;
          schemeModel.shortName = scheme.shortName;
          schemeModel.fullName = scheme.fullName;
          schemeModel.fundHouseShortName = scheme.fund.fundHouse.shortName;
          schemeModel.fundHouseFullName = scheme.fund.fundHouse.fullName;
          schemeModel.fundShortName = scheme.fund.shortName;
          schemeModel.fundFullName = scheme.fund.fullName;
          schemeModel.schemeManager = scheme.schemeManager;
          schemeModel.launchDate = scheme.launchDate;
          schemeModel.date1 = scheme.date1;
          schemeModel.date2 = scheme.date2;
          schemeModel.date3 = scheme.date3;
          schemeModel.date4 = scheme.date4;
          schemeModel.date5 = scheme.date5;
          schemeModel.date6 = scheme.date6;

          return schemeModel;
        });

        this.gridDatasource = new MatTableDataSource(schemeModels);
        this.gridDatasource.paginator = this.paginator;
        this.gridDatasource.sort = this.gridSort;
        this.range.total = response.dataTotalCount;

        this.gridDatasource.filterPredicate = (data, filter: string) => {
          const accumulator = (currentTerm, key) => {
            if (key === 'launchDate') {
              return currentTerm + (this.datePipe.transform(data.launchDate, APPLICATION_DATE_FORMAT));
            }
            else if (key === 'date1') {
              return currentTerm + (this.datePipe.transform(data.date1, APPLICATION_DATE_FORMAT));
            }
            else if (key === 'date2') {
              return currentTerm + (this.datePipe.transform(data.date2, APPLICATION_DATE_FORMAT));
            }
            else if (key === 'date3') {
              return currentTerm + (this.datePipe.transform(data.date3, APPLICATION_DATE_FORMAT));
            }
            else if (key === 'date4') {
              return currentTerm + (this.datePipe.transform(data.date4, APPLICATION_DATE_FORMAT));
            }
            else if (key === 'date5') {
              return currentTerm + (this.datePipe.transform(data.date5, APPLICATION_DATE_FORMAT));
            }
            else if (key === 'date6') {
              return currentTerm + (this.datePipe.transform(data.date6, APPLICATION_DATE_FORMAT));
            }

            return currentTerm + data[key];

          };
          const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
          const transformedFilter = filter.trim().toLowerCase();
          return dataStr.indexOf(transformedFilter) !== -1;
        };
      }
    });
  }

  public async onPage(event: PageEvent) {
    this.range.limit = event.pageSize;
    this.range.offset = event.pageIndex * event.pageSize;
    await this.loadFunds();
  }

  openAddDialog() {
    let dialogRef = this.dialogModel.open(AddUpdateSchemeComponent, {
      disableClose: false,
      //panelClass: 'fullscreen-dialog',
      width: '50%',
    });
    dialogRef.backdropClick().subscribe(_ => {
      dialogRef.close();
    })
    // When user close the dialog
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadFunds();
      }
    });

  }


  openEditDialog(scheme: SchemeListItemModel) {
    let dialogRef = this.dialogModel.open(AddUpdateSchemeComponent, {
      disableClose: false,
      width: '50%',
      data: scheme
    });
    dialogRef.backdropClick().subscribe(_ => {
      dialogRef.close();
    })
    // When user close the dialog
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadFunds();
      }
    });

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.gridDatasource.filter = filterValue.trim().toLowerCase();

    if (this.gridDatasource.paginator) {
      this.gridDatasource.paginator.firstPage();
    }
  }

  private setLoading(isLoading: boolean) {
    this.loading = isLoading;
    if (isLoading) {
      this.gridDatasource = new MatTableDataSource([]);
    }
  }
}
