<div class="dialog-custom-content-wrap dialog-custom-box">
  <div>
    <div class="mt-3">
      <div class="d-flex align-items-center justify-content-between bg-white py-2 px-3 main-div">
        <h2 class="dialog-custom-header-text m-0">Documents</h2>
        <div class="d-flex align-items-center gap-3">
          <div class="user-grid-head">
            <mat-form-field style="margin-right: 8px;" appearance="outline">
              <mat-label>Document Type</mat-label>
              <mat-select style="font-weight: normal;" matInput appearance="legacy" [(value)]="selectedDocumentTypeId"
                (selectionChange)="documentTypeSelectionChange()">
                <mat-option *ngFor="let d of documentTypes" [value]="d.id">
                  {{d.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Filter</mat-label>
              <input matInput (keyup)="applyFilter($event)" placeholder="Ex. File Name" #input>
            </mat-form-field>
          </div>
          <button mat-raised-button color="primary" class="me-3 w-100 rounded-pill btn-ht" (click)="openAddDialog()"
            *ngIf="featurePermissionService.canAddDocument">
            <mat-icon class="material-icons-outlined">home</mat-icon>
            Add Document</button>
        </div>
      </div>

      <div *ngIf="featurePermissionService.canLinkInvestorDocument"
        class="d-flex align-items-center justify-content-end bg-white py-2 px-3 main-div">
        <div class="d-flex align-items-center gap-3 justify-content-end" style="width: 100%;">
          <a href="{{Environment.apiBaseUrl}}/Investor_Document_Link_Template.xlsx">Document Link Template</a>
          <button mat-raised-button color="primary" class="me-3 rounded-pill btn-ht"
            (click)="openInvestorDocumentLinkDialog()" style="margin-right: 0px !important;">
            <mat-icon class="material-icons-outlined">link</mat-icon>
            Link Document</button>
          <button mat-raised-button color="primary" class="me-3 rounded-pill btn-ht" (click)="exportToExcel()">
            Export To Excel<mat-icon class="material-icons-outlined">download</mat-icon></button>
        </div>
      </div>

      <mat-card-content>
        <div fxLayout="column">
          <mat-table matTableExporter [dataSource]="gridDatasource" class="body-text" matSort #gridSort="matSort"
            #exporter="matTableExporter" [hiddenColumns]="[15]">
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef class="column-5 fw-semibold" mat-sort-header> Id </th>
              <td mat-cell *matCellDef="let item">
                {{item?.id}}
              </td>
            </ng-container>

            <ng-container matColumnDef="fileName">
              <th mat-header-cell *matHeaderCellDef class="column-5 fw-semibold" mat-sort-header> File Name </th>
              <td mat-cell *matCellDef="let item">
                {{item?.fileName}}
              </td>
            </ng-container>

            <ng-container matColumnDef="documentType">
              <th mat-header-cell *matHeaderCellDef class="column-5  fw-semibold" mat-sort-header> Document Type </th>
              <td mat-cell *matCellDef="let item">{{item?.documentType}}</td>
            </ng-container>

            <ng-container matColumnDef="uploadedOn">
              <th mat-header-cell *matHeaderCellDef class="column-5  fw-semibold" mat-sort-header> Upload Date </th>
              <td mat-cell *matCellDef="let item">{{item?.uploadedOn | date:APPLICATION_DATE_TIME_FORMAT}}</td>
            </ng-container>

            <ng-container matColumnDef="uploadedByName">
              <th mat-header-cell *matHeaderCellDef class="column-5  fw-semibold" mat-sort-header> Uploaded By </th>
              <td mat-cell *matCellDef="let item">{{item?.uploadedByName}}</td>
            </ng-container>

            <ng-container matColumnDef="updatedOn">
              <th mat-header-cell *matHeaderCellDef class="column-5  fw-semibold" mat-sort-header> Update Date </th>
              <td mat-cell *matCellDef="let item">{{item?.updatedOn | date:APPLICATION_DATE_TIME_FORMAT}}</td>
            </ng-container>

            <ng-container matColumnDef="updatedByName">
              <th mat-header-cell *matHeaderCellDef class="column-5  fw-semibold" mat-sort-header> Updated By </th>
              <td mat-cell *matCellDef="let item">{{item?.updatedByName}}</td>
            </ng-container>

            <ng-container matColumnDef="fundHouseFullName">
              <th mat-header-cell *matHeaderCellDef class="column-5 fw-semibold" mat-sort-header> Fund House</th>
              <td mat-cell *matCellDef="let item">{{item.fundHouseFullName}}</td>
            </ng-container>

            <ng-container matColumnDef="isLinked">
              <th mat-header-cell *matHeaderCellDef class="column-5 fw-semibold" mat-sort-header> Linked</th>
              <td mat-cell *matCellDef="let item">{{item.isLinked?'Yes':'No'}}</td>
            </ng-container>

            <ng-container matColumnDef="linkedOn">
              <th mat-header-cell *matHeaderCellDef class="column-5  fw-semibold" mat-sort-header> Linked Date </th>
              <td mat-cell *matCellDef="let item">{{item?.linkedOn | date:APPLICATION_DATE_TIME_FORMAT}}</td>
            </ng-container>

            <ng-container matColumnDef="linkedByName">
              <th mat-header-cell *matHeaderCellDef class="column-5  fw-semibold" mat-sort-header> Linked By </th>
              <td mat-cell *matCellDef="let item">{{item?.linkedByName}}</td>
            </ng-container>


            <ng-container matColumnDef="investorName">
              <th mat-header-cell *matHeaderCellDef class="column-5 fw-semibold" mat-sort-header>Investor</th>
              <td mat-cell *matCellDef="let item">{{item.investorName}}</td>
            </ng-container>

            <ng-container matColumnDef="schemeShortName">
              <th mat-header-cell *matHeaderCellDef class="column-5 fw-semibold" mat-sort-header>Scheme Short Name</th>
              <td mat-cell *matCellDef="let item">{{item.schemeShortName}}</td>
            </ng-container>

            <ng-container matColumnDef="schemeFullName">
              <th mat-header-cell *matHeaderCellDef class="column-5 fw-semibold" mat-sort-header>Scheme Full Name</th>
              <td mat-cell *matCellDef="let item">{{item.schemeFullName}}</td>
            </ng-container>

            <ng-container matColumnDef="fundFullName">
              <th mat-header-cell *matHeaderCellDef class="column-5 fw-semibold" mat-sort-header> Fund</th>
              <td mat-cell *matCellDef="let item">{{item.fundFullName}}</td>
            </ng-container>


            <!-- <ng-container matColumnDef="noticeDate">
              <th mat-header-cell *matHeaderCellDef class="column-5  fw-semibold" mat-sort-header> Notice </th>
              <td mat-cell *matCellDef="let item">{{item?.noticeDate | date:APPLICATION_DATE_FORMAT}}</td>
            </ng-container>

            <ng-container matColumnDef="dueDate">
              <th mat-header-cell *matHeaderCellDef class="column-5  fw-semibold" mat-sort-header> Due Date </th>
              <td mat-cell *matCellDef="let item">{{item?.dueDate| date:APPLICATION_DATE_FORMAT}}</td>
            </ng-container>

            <ng-container matColumnDef="statementAsOnDate">
              <th mat-header-cell *matHeaderCellDef class="column-5  fw-semibold" mat-sort-header> Statement Date </th>
              <td mat-cell *matCellDef="let item">{{item?.statementAsOnDate| date:APPLICATION_DATE_FORMAT}}</td>
            </ng-container>

            <ng-container matColumnDef="statementIssueDate">
              <th mat-header-cell *matHeaderCellDef class="column-5  fw-semibold" mat-sort-header> Statement Issue Date
              </th>
              <td mat-cell *matCellDef="let item">{{item?.statementIssueDate | date:APPLICATION_DATE_FORMAT}}</td>
            </ng-container>

            <ng-container matColumnDef="distributionDate">
              <th mat-header-cell *matHeaderCellDef class="column-5  fw-semibold" mat-sort-header> Distribution Date
              </th>
              <td mat-cell *matCellDef="let item">{{item?.distributionDate| date:APPLICATION_DATE_FORMAT}}</td>
            </ng-container>

            <ng-container matColumnDef="newsletterDate">
              <th mat-header-cell *matHeaderCellDef class="column-5  fw-semibold" mat-sort-header> Date
              </th>
              <td mat-cell *matCellDef="let item">{{item?.newsletterDate| date:APPLICATION_DATE_FORMAT}}</td>
            </ng-container>

            <ng-container matColumnDef="financialYear">
              <th mat-header-cell *matHeaderCellDef class="column-5  fw-semibold" mat-sort-header> Financial Year
              </th>
              <td mat-cell *matCellDef="let item">{{item?.financialYear}}</td>
            </ng-container>

            <ng-container matColumnDef="advanceTaxDate">
              <th mat-header-cell *matHeaderCellDef class="column-5  fw-semibold" mat-sort-header> Date
              </th>
              <td mat-cell *matCellDef="let item">{{item?.advanceTaxDate| date:APPLICATION_DATE_FORMAT}}</td>
            </ng-container> -->

            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef class="column-5  fw-semibold"> </th>
              <td mat-cell *matCellDef="let item">
                <button mat-icon-button matTooltip="Click to Download" class="iconbutton" color="primary">
                  <mat-icon aria-label="Download" (click)="downloadDocument(item.id, item.fileName)">download</mat-icon>
                </button>
                <button mat-icon-button [hidden]="!featurePermissionService.canEditDocument || !item.canEditDocument"
                  matTooltip="Click to Edit" class="iconbutton" color="primary">
                  <mat-icon aria-label="Edit" (click)="openEditDialog(item)">edit</mat-icon>
                </button>
                <button mat-icon-button [hidden]="!featurePermissionService.canDeleteDocument"
                  matTooltip="Click to Delete" class="iconbutton" color="primary">
                  <mat-icon aria-label="Delete" (click)="deleteDocument(item.id)">delete</mat-icon>
                </button>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="gridColumns">
            </tr>
            <tr mat-row *matRowDef="let row; columns: gridColumns;">
            </tr>
            <tr class="mat-row no-data-row" *matNoDataRow>
              <td *ngIf="loading" class="mat-cell-load" [attr.colspan]="gridColumns.length">
                <div class="spinner">
                  <mat-spinner></mat-spinner>
                </div>
              </td>
              <td *ngIf="!loading" style="text-align: center;" class="mat-cell" [attr.colspan]="gridColumns.length">
                No data matching the filter.
              </td>
            </tr>
          </mat-table>
          <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="25"
            aria-label="Select page of fund houses"></mat-paginator>
        </div>
      </mat-card-content>
    </div>

  </div>
</div>