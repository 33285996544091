import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { InvestorProfileItemModel } from '@app/_models/investor';
import { LocalStorageService } from '@app/_services/local-storage.service';
import { AppBaseComponent } from '@app/app-base.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent extends AppBaseComponent implements OnInit {
  gridColumns: string[] = ['investor', 'fundHouse', 'fund', 'schemeShortName', 'schemeFullName'];
  gridDatasource: MatTableDataSource<InvestorProfileItemModel>;
  investorProfileItems: InvestorProfileItemModel[] = [];
  constructor(
    localStorageService: LocalStorageService,
    activatedRoute: ActivatedRoute
  ) {

    super(activatedRoute, localStorageService);
  }

  public async ngOnInit(): Promise<void> {
    console.log(this.localStorageService.currentUser);
    this.investorProfileItems = this.localStorageService.currentUser.schemeInvestors.map(x => {
      return {
        investorId: x.investorId,
        investor: x.investor.fullName,
        fundHouse: x.fundHouseFullName,
        fund: x.fundFullName,
        schemeShortName: x.schemeShortName,
        schemeFullName: x.schemeFullName,
        schemeCount: this.localStorageService.currentUser.schemeInvestors.filter(y => y.investorId == x.investorId).length
      } as InvestorProfileItemModel;
    });

    let index = 0;
    let lastInvestorId = 0;
    this.investorProfileItems.forEach(x => {
      if (lastInvestorId === 0) {
        lastInvestorId = x.investorId;
      }
      else if (lastInvestorId !== x.investorId) {
        lastInvestorId = x.investorId;
        index = 0;
      }

      x.partitionIndex = index;
      index++;
    });

    this.gridDatasource = new MatTableDataSource(this.investorProfileItems);
  }
}
