import { ModelBase } from "./model-base";

export class InvestorListItemModel extends ModelBase {
  public fullName: string = '';
  public identityPanNumber: string;
  public identifier: string;
  public isActive: boolean;
  public activeStatus: string;
  public fundHouseName: string;

  public fundHouseId: number;
}

export class InvestorProfileItemModel {
  public investorId: number;
  public investor: string;
  public fundHouse: string;
  public fund: string;
  public schemeShortName: string;
  public schemeFullName: string;
  public schemeCount: number;
  public partitionIndex: number;
}