import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AdminService } from '@app/_services/admin.service';
import { AlertsService } from '@app/_services/alerts.service';
import { FundDto } from '@app/_services/dto/fund.dto';
import { LocalStorageService } from '@app/_services/local-storage.service';
import { AppBaseComponent } from '@app/app-base.component';

@Component({
  selector: 'app-link-investor-document',
  templateUrl: 'link-investor-document.component.html',
  styleUrls: ['link-investor-document.component.scss']
})
export class LinkInvestorDocumentComponent extends AppBaseComponent implements OnInit {

  form!: FormGroup;
  submitted = false;
  loading = false;
  error = '';

  constructor(
    public dialogRef: MatDialogRef<LinkInvestorDocumentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FundDto,
    private fb: FormBuilder,
    private alertsService: AlertsService,
    private adminService: AdminService,
    localStorageService: LocalStorageService,
    activatedRoute: ActivatedRoute
  ) {

    super(activatedRoute, localStorageService);

    dialogRef.disableClose = false;
    dialogRef.backdropClick().subscribe(_ => {
      dialogRef.close();
    })
  }

  async ngOnInit() {
    this.form = this.fb.group({
      fileName: ['', Validators.required]
    }
    );
  }

  get formControl() {
    return this.form.controls;
  }

  async onSubmit() {
    this.submitted = true;
    if (this.form.valid) {

      let formData = new FormData();
      formData.append('file', this.selectedFile);

      this.loading = true;
      (await this.adminService.linkInvestorDocument(formData))
        .subscribe((response: Blob) => {
          this.loading = false;
          console.log(response.text());
          console.log(response);
          if (response.size === 0) {
            this.alertsService.showInfo(`Investors are linked to documents successfully!.`, "Message", "");
            this.dialogRef.close(true);
          }
          else {
            if (response.type === 'text/plain') {
              response.text().then(error => {
                this.alertsService.showInfo(error, "Message", "");
              });
            } else {
              this.alertsService.showInfo(`Errors in records! Error file has been downloaded. Please correct the errors and re-upload.`, "Message", "");
              const blob = new Blob([response]);
              const downloadLink = document.createElement('a');
              downloadLink.href = URL.createObjectURL(blob);
              downloadLink.download = "Error.xlsx";
              downloadLink.click();
              URL.revokeObjectURL(downloadLink.href);

              this.dialogRef.close(true);
            }
          }
        });
    }
  }
  onNoClick(): void {
    this.dialogRef.close(false);
  }

  selectedFile: File = null;

  onFileSelected(event: any): void {
    this.selectedFile = event.target.files[0] ?? null;
    this.formControl.fileName.setValue(this.selectedFile.name);
  }
}
