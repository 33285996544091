import { DtoBase } from "./base.dto";
import { FundHouseDto } from "./fund-house.dto";

export class FundDto extends DtoBase<number> {
  public shortName: string = '';
  public fullName: string = '';
  public sebiRegistrationNumber: string = '';
  public investmentManagerName: string = '';
  public sponserName: string = '';
  public merchantBankerName: string = '';
  public legalAdvisorName: string = '';
  public fundHouseId: number = null;
  public fundHouse: FundHouseDto = null;
}