import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';

import { RoutePaths } from '@app/_constants';
import { Role } from '@app/_models';
import { AuthenticationService } from '@app/_services';
import { UserDto } from '@app/_services/dto/user.dto';

@Component({
    selector: 'app-login',
    templateUrl: 'login.component.html',
    styleUrls: ['login.component.scss']
})
export class LoginComponent implements OnInit {
    loginForm!: FormGroup;
    codeForm!: FormGroup;
    loading = false;
    submitted = false;
    isLogin = false;
    error = null;
    successMessage = null
    fundHouseLogo: string = null;
    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        public authenticationService: AuthenticationService,
    ) {
        // redirect to home if already logged in
        if (this.authenticationService.currentUser) {
            this.router.navigate([this.getUserLandingPage(this.authenticationService.currentUser)]);
        }

        this.fundHouseLogo = this.route.snapshot.data['fundHouseLogoResponse']?.data;
    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            username: ['', [Validators.required, Validators.email]]
        });
        this.codeForm = this.formBuilder.group({
            username: ['', Validators.required],
            code: ['', Validators.required]
        });
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    // convenience getter for easy access to form fields
    get c() { return this.codeForm.controls; }

    onSubmit() {
        this.error = null;
        this.successMessage = null;
        // stop here if form is invalid
        if (this.loginForm.valid) {
            var response: any = {};// this.authenticationService.loginOffline();
            response.isSuccess = false;
            if (response.isSuccess && response.data) {
                localStorage.setItem('user', JSON.stringify(response.data.user));
                localStorage.setItem('token', JSON.stringify(response.data.token));
                // get return url from query parameters or default to home page
                const returnUrl = this.route.snapshot.queryParams['returnUrl'] || this.getUserLandingPage(response.data.user);
                this.router.navigateByUrl(returnUrl);
            }
            else {
                this.submitted = true;
                this.loading = true;
                this.authenticationService.login(this.f.username.value)
                    //.pipe(first())
                    .subscribe({
                        next: (response) => {
                            this.submitted = false;
                            this.loading = false;
                            if (response.isSuccess) {
                                this.isLogin = true;
                                this.c.username.setValue(this.f.username.value);
                                if (response.data && response.data.user && response.data.token) {
                                    localStorage.setItem('user', JSON.stringify(response.data.user));
                                    localStorage.setItem('token', JSON.stringify(response.data.token));
                                    // get return url from query parameters or default to home page
                                    const returnUrl = this.route.snapshot.queryParams['returnUrl'] || this.getUserLandingPage(response.data.user);
                                    this.router.navigateByUrl(returnUrl);
                                }
                                this.successMessage = "OTP has been sent to your email.";
                            }
                            else {
                                //Need to display message
                                this.error = response.message;
                            }
                        },
                        error: error => {
                            this.error = error;
                            this.loading = false;
                        }
                    });
            }
        }
    }

    async onCodeSubmit() {
        this.error = null;
        this.successMessage = null;
        this.submitted = true;

        // stop here if form is invalid
        if (this.codeForm.invalid) {
            return;
        }

        this.loading = true;
        this.authenticationService.login2Factor(this.c.code.value)
            .pipe(first())
            .subscribe({
                next: async (response) => {
                    this.isLogin = true;
                    this.submitted = false;
                    this.loading = false;
                    if (response.isSuccess) {
                        localStorage.setItem('user', JSON.stringify(response.data.user));
                        localStorage.setItem('token', JSON.stringify(response.data.token));

                        // get return url from query parameters or default to home page
                        const returnUrl = this.route.snapshot.queryParams['returnUrl'] || this.getUserLandingPage(response.data.user);
                        this.router.navigateByUrl(returnUrl);
                    }
                    else {
                        //Need to display message
                        this.error = response.message;
                    }
                },
                error: error => {
                    this.error = error;
                    this.loading = false;
                }
            });
    }

    private getUserLandingPage(user: UserDto) {

        if (user.role === Role.Admin) {
            return RoutePaths.fundHouses;
        }
        else if (user.role == Role.FundHouseAdmin) {
            return RoutePaths.funds;
        }
        else if (user.role == Role.FundAdmin) {
            return RoutePaths.schemes;
        }
        else {
            return RoutePaths.documents;
        }
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        setTimeout(() => {
            const screenHeight = window.innerHeight;
            const header = document.getElementById('main-header')?.clientHeight;
            const footer = document.getElementById('footer')?.clientHeight;
            const finalHeight = screenHeight - (header + footer);
            const mainContainer = document.getElementById('main-container');
            if (mainContainer && !isNaN(finalHeight)) {
                mainContainer.style.height = finalHeight + "px";
            }
        }, 500);
    }
}
