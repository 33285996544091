export class RoutePaths {

    public static readonly login = 'login';
    public static readonly funds = 'funds';
    public static readonly fundHouses = 'fund-houses';
    public static readonly fundHouseAdmins = 'fund-house-admins';
    public static readonly investors = 'investors';
    public static readonly home = 'home';
    public static readonly confirmEmail = 'confirm-email/:token/:email';
    public static readonly documents = 'documents';
    public static readonly schemes = 'schemes';
    public static readonly fundAdmins = 'fund-admins';
    public static readonly schemeInvestors = 'scheme-investors';
    public static readonly profile = 'profile';
}