<div class="dialog-custom-content-wrap dialog-custom-box">
  <div>
    <div class="mt-3">
      <div class="d-flex align-items-center justify-content-between bg-white py-2 px-3">
        <h2 class="dialog-custom-header-text m-0">Scheme Investors</h2>
        <div class="d-flex align-items-center gap-3">
          <div class="user-grid-head">
            <div class="column-toggle-field">
              <mat-form-field style="margin-right: 8px;" appearance="outline">
                <input type="text" matInput hidden="true">
                <mat-checkbox matInput (change)="toggleColumnsDisplay($event)" style="font-weight: normal;"> All Columns
                </mat-checkbox>
              </mat-form-field>
            </div>
            <mat-form-field appearance="outline">
              <mat-label>Filter</mat-label>
              <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Email" #input>
            </mat-form-field>
          </div>
          <button mat-raised-button color="primary" class="me-3 w-100 rounded-pill btn-ht" (click)="addUserDialog()"
            *ngIf="featurePermissionService.canAddInvestor">
            <mat-icon class="material-icons-outlined">person</mat-icon>
            Add Scheme Investor</button>
        </div>
      </div>

      <mat-card-content>
        <div fxLayout="column">
          <mat-table [ngStyle]="{'display':tableDisplay}" [dataSource]="gridDatasource" class="body-text" matSort
            #empTbSort="matSort">
            <ng-container matColumnDef="investorId">
              <th mat-header-cell *matHeaderCellDef class="column-5 fw-semibold" mat-sort-header> Investor Id </th>
              <td mat-cell *matCellDef="let item">
                {{item?.investorId}}
              </td>
            </ng-container>
            <ng-container matColumnDef="schemeId">
              <th mat-header-cell *matHeaderCellDef class="column-5 fw-semibold" mat-sort-header> Scheme Id </th>
              <td mat-cell *matCellDef="let item">
                {{item?.schemeId}}
              </td>
            </ng-container>
            <ng-container matColumnDef="investorName">
              <th mat-header-cell *matHeaderCellDef class="column-5 fw-semibold" mat-sort-header> Investor Name </th>
              <td mat-cell *matCellDef="let item">
                {{item?.investorName}}
              </td>
            </ng-container>
            <ng-container matColumnDef="identityPanNumber">
              <th mat-header-cell *matHeaderCellDef class="column-5 fw-semibold" mat-sort-header> Identity Pan Numer
              </th>
              <td mat-cell *matCellDef="let item">
                {{item?.investor.identityPanNumber}}
              </td>
            </ng-container>
            <ng-container matColumnDef="identifier">
              <th mat-header-cell *matHeaderCellDef class="column-5 fw-semibold" mat-sort-header> Identifier </th>
              <td mat-cell *matCellDef="let item">
                {{item?.investor.identifier}}
              </td>
            </ng-container>
            <ng-container matColumnDef="schemeShortName">
              <th mat-header-cell *matHeaderCellDef class="column-5 fw-semibold" mat-sort-header> Scheme Short Name
              </th>
              <td mat-cell *matCellDef="let item">
                {{item?.schemeShortName}}
              </td>
            </ng-container>
            <ng-container matColumnDef="schemeFullName">
              <th mat-header-cell *matHeaderCellDef class="column-5 fw-semibold" mat-sort-header> Scheme Full Name </th>
              <td mat-cell *matCellDef="let item">
                {{item?.schemeFullName}}
              </td>
            </ng-container>
            <ng-container matColumnDef="fundName">
              <th mat-header-cell *matHeaderCellDef class="column-5 fw-semibold" mat-sort-header> Fund </th>
              <td mat-cell *matCellDef="let item">
                {{item?.fundFullName}}
              </td>
            </ng-container>
            <ng-container matColumnDef="fundHouseName">
              <th mat-header-cell *matHeaderCellDef class="column-5 fw-semibold" mat-sort-header> Fund House</th>
              <td mat-cell *matCellDef="let item">
                {{item?.fundHouseFullName}}
              </td>
            </ng-container>
            <ng-container matColumnDef="email1">
              <th mat-header-cell *matHeaderCellDef class="column-5 fw-semibold" mat-sort-header> Email 1</th>
              <td mat-cell *matCellDef="let item">{{item.email1}}</td>
            </ng-container>
            <ng-container matColumnDef="email2">
              <th mat-header-cell *matHeaderCellDef class="column-5 fw-semibold" mat-sort-header> Email 2</th>
              <td mat-cell *matCellDef="let item">{{item.email2}}</td>
            </ng-container>
            <ng-container matColumnDef="email3">
              <th mat-header-cell *matHeaderCellDef class="column-5 fw-semibold" mat-sort-header> Email 3</th>
              <td mat-cell *matCellDef="let item">{{item.email3}}</td>
            </ng-container>
            <ng-container matColumnDef="email4">
              <th mat-header-cell *matHeaderCellDef class="column-5 fw-semibold" mat-sort-header> Email 4</th>
              <td mat-cell *matCellDef="let item">{{item.email4}}</td>
            </ng-container>
            <ng-container matColumnDef="email5">
              <th mat-header-cell *matHeaderCellDef class="column-5 fw-semibold" mat-sort-header> Email 5</th>
              <td mat-cell *matCellDef="let item">{{item.email5}}</td>
            </ng-container>
            <ng-container matColumnDef="folioNo">
              <th mat-header-cell *matHeaderCellDef class="column-5 fw-semibold" mat-sort-header> Folio No.</th>
              <td mat-cell *matCellDef="let item">{{item.folioNo}}</td>
            </ng-container>
            <ng-container matColumnDef="isCarryClass">
              <th mat-header-cell *matHeaderCellDef class="column-5 fw-semibold" mat-sort-header> Carry Class</th>
              <td mat-cell *matCellDef="let item">{{item.isCarryClass?'Yes':'No'}}</td>
            </ng-container>
            <ng-container matColumnDef="class">
              <th mat-header-cell *matHeaderCellDef class="column-5 fw-semibold" mat-sort-header> Class</th>
              <td mat-cell *matCellDef="let item">{{item.class}}</td>
            </ng-container>
            <ng-container matColumnDef="forfeitAndTransferDate">
              <th mat-header-cell *matHeaderCellDef class="column-5 fw-semibold" mat-sort-header> Forfeit And Transfer
                Date
              </th>
              <td mat-cell *matCellDef="let item">{{item.forfeitAndTransferDate | date:APPLICATION_DATE_FORMAT}}</td>
            </ng-container>
            <ng-container matColumnDef="salutation">
              <th mat-header-cell *matHeaderCellDef class="column-5 fw-semibold" mat-sort-header> Salutation</th>
              <td mat-cell *matCellDef="let item">{{item.salutation}}</td>
            </ng-container>
            <ng-container matColumnDef="modeOfHolding">
              <th mat-header-cell *matHeaderCellDef class="column-5 fw-semibold" mat-sort-header> Mode Of Holding</th>
              <td mat-cell *matCellDef="let item">{{item.modeOfHolding}}</td>
            </ng-container>
            <ng-container matColumnDef="taxPanNumber">
              <th mat-header-cell *matHeaderCellDef class="column-5 fw-semibold" mat-sort-header> Tax Pan Number</th>
              <td mat-cell *matCellDef="let item">{{item.taxPanNumber}}</td>
            </ng-container>
            <ng-container matColumnDef="contactNo">
              <th mat-header-cell *matHeaderCellDef class="column-5 fw-semibold" mat-sort-header> Contact No</th>
              <td mat-cell *matCellDef="let item">{{item.contactNo}}</td>
            </ng-container>
            <ng-container matColumnDef="address">
              <th mat-header-cell *matHeaderCellDef class="column-5 fw-semibold" mat-sort-header> Address</th>
              <td mat-cell *matCellDef="let item">{{item.address}}</td>
            </ng-container>
            <ng-container matColumnDef="address1">
              <th mat-header-cell *matHeaderCellDef class="column-5 fw-semibold" mat-sort-header> Address 1</th>
              <td mat-cell *matCellDef="let item">{{item.address1}}</td>
            </ng-container>
            <ng-container matColumnDef="address2">
              <th mat-header-cell *matHeaderCellDef class="column-5 fw-semibold" mat-sort-header> Address 2</th>
              <td mat-cell *matCellDef="let item">{{item.address2}}</td>
            </ng-container>
            <ng-container matColumnDef="city">
              <th mat-header-cell *matHeaderCellDef class="column-5 fw-semibold" mat-sort-header> City</th>
              <td mat-cell *matCellDef="let item">{{item.city}}</td>
            </ng-container>
            <ng-container matColumnDef="state">
              <th mat-header-cell *matHeaderCellDef class="column-5 fw-semibold" mat-sort-header> state</th>
              <td mat-cell *matCellDef="let item">{{item.state}}</td>
            </ng-container>
            <ng-container matColumnDef="pincode">
              <th mat-header-cell *matHeaderCellDef class="column-5 fw-semibold" mat-sort-header> Pincode</th>
              <td mat-cell *matCellDef="let item">{{item.pincode}}</td>
            </ng-container>
            <ng-container matColumnDef="bankAccountType">
              <th mat-header-cell *matHeaderCellDef class="column-5 fw-semibold" mat-sort-header> Bank Account Type</th>
              <td mat-cell *matCellDef="let item">{{item.bankAccountType}}</td>
            </ng-container>
            <ng-container matColumnDef="bankName">
              <th mat-header-cell *matHeaderCellDef class="column-5 fw-semibold" mat-sort-header> Bank Name</th>
              <td mat-cell *matCellDef="let item">{{item.bankName}}</td>
            </ng-container>
            <ng-container matColumnDef="ifscCode">
              <th mat-header-cell *matHeaderCellDef class="column-5 fw-semibold" mat-sort-header> IFSC Code</th>
              <td mat-cell *matCellDef="let item">{{item.ifscCode}}</td>
            </ng-container>
            <ng-container matColumnDef="micrCode">
              <th mat-header-cell *matHeaderCellDef class="column-5 fw-semibold" mat-sort-header> MICR Code</th>
              <td mat-cell *matCellDef="let item">{{item.micrCode}}</td>
            </ng-container>
            <ng-container matColumnDef="accountNumber">
              <th mat-header-cell *matHeaderCellDef class="column-5 fw-semibold" mat-sort-header> Account Number</th>
              <td mat-cell *matCellDef="let item">{{item.accountNumber}}</td>
            </ng-container>
            <ng-container matColumnDef="taxStatus">
              <th mat-header-cell *matHeaderCellDef class="column-5 fw-semibold" mat-sort-header> Tax Status</th>
              <td mat-cell *matCellDef="let item">{{item.taxStatus}}</td>
            </ng-container>
            <ng-container matColumnDef="setupFees">
              <th mat-header-cell *matHeaderCellDef class="column-5 fw-semibold" mat-sort-header> Setup Fees</th>
              <td mat-cell *matCellDef="let item">{{item.setupFees}}</td>
            </ng-container>
            <ng-container matColumnDef="managementFees">
              <th mat-header-cell *matHeaderCellDef class="column-5 fw-semibold" mat-sort-header> Management Fees</th>
              <td mat-cell *matCellDef="let item">{{item.managementFees}}</td>
            </ng-container>
            <ng-container matColumnDef="operatingExpenses">
              <th mat-header-cell *matHeaderCellDef class="column-5 fw-semibold" mat-sort-header> Operating Expenses
              </th>
              <td mat-cell *matCellDef="let item">{{item.operatingExpenses}}</td>
            </ng-container>
            <ng-container matColumnDef="sebiInvestorType1">
              <th mat-header-cell *matHeaderCellDef class="column-5 fw-semibold" mat-sort-header> Sebi Investor Type1
              </th>
              <td mat-cell *matCellDef="let item">{{item.sebiInvestorType1}}</td>
            </ng-container>
            <ng-container matColumnDef="sebiInvestorType2">
              <th mat-header-cell *matHeaderCellDef class="column-5 fw-semibold" mat-sort-header> Sebi Investor Type2
              </th>
              <td mat-cell *matCellDef="let item">{{item.sebiInvestorType2}}</td>
            </ng-container>
            <ng-container matColumnDef="sebiInvestorType3">
              <th mat-header-cell *matHeaderCellDef class="column-5 fw-semibold" mat-sort-header> Sebi Investor Type 3
              </th>
              <td mat-cell *matCellDef="let item">{{item.sebiInvestorType3}}</td>
            </ng-container>
            <ng-container matColumnDef="capitalCommitment">
              <th mat-header-cell *matHeaderCellDef class="column-5 fw-semibold" mat-sort-header> Capital Commitment
              </th>
              <td mat-cell *matCellDef="let item">{{item.capitalCommitment}}</td>
            </ng-container>
            <ng-container matColumnDef="capitalContribution">
              <th mat-header-cell *matHeaderCellDef class="column-5 fw-semibold" mat-sort-header> Capital Contribution
              </th>
              <td mat-cell *matCellDef="let item">{{item.capitalContribution}}</td>
            </ng-container>
            <ng-container matColumnDef="kpfsRecordStatus">
              <th mat-header-cell *matHeaderCellDef class="column-5 fw-semibold" mat-sort-header> KPFS Record Status
              </th>
              <td mat-cell *matCellDef="let item">{{item.kpfsRecordStatus}}</td>
            </ng-container>
            <ng-container matColumnDef="kpfsIncompleteRecordRemark">
              <th mat-header-cell *matHeaderCellDef class="column-5 fw-semibold" mat-sort-header>
                KPFS Incomplete Record Remark</th>
              <td mat-cell *matCellDef="let item">{{item.kpfsIncompleteRecordRemark}}</td>
            </ng-container>
            <ng-container matColumnDef="actions" stickyEnd>
              <th mat-header-cell [hidden]="!featurePermissionService.canEditInvestor" *matHeaderCellDef
                class="column-5  fw-semibold"> </th>
              <td mat-cell [hidden]="!featurePermissionService.canEditInvestor" *matCellDef="let item">
                <button mat-icon-button matTooltip="Click to Edit" class="iconbutton" color="primary">
                  <mat-icon aria-label="Edit" (click)="openEditDialog(item)">edit</mat-icon>
                </button>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="gridColumns">
            </tr>
            <tr mat-row *matRowDef="let row; columns: gridColumns;">
            </tr>
            <tr class="mat-row no-data-row" *matNoDataRow>
              <td *ngIf="loading" class="mat-cell-load" [attr.colspan]="gridColumns.length">
                <div class="spinner">
                  <mat-spinner></mat-spinner>
                </div>
              </td>
              <td *ngIf="!loading" style="text-align: center;" class="mat-cell" [attr.colspan]="gridColumns.length">
                No data matching the filter.
              </td>
            </tr>
          </mat-table>
          <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="25"
            aria-label="Select page of users"></mat-paginator>
        </div>
      </mat-card-content>
    </div>

  </div>
</div>