import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { SchemeListItemModel } from '@app/_models/scheme';
import { AdminService } from '@app/_services/admin.service';
import { AlertsService } from '@app/_services/alerts.service';
import { CommonService } from '@app/_services/common.service';
import { FundHouseDto } from '@app/_services/dto/fund-house.dto';
import { FundDto } from '@app/_services/dto/fund.dto';
import { AddUpdateSchemeDto } from '@app/_services/dto/scheme.dto';
import { LocalStorageService } from '@app/_services/local-storage.service';
import { AppBaseComponent } from '@app/app-base.component';
import { first } from 'rxjs';

@Component({
  selector: 'app-add-update-scheme',
  templateUrl: 'add-update-scheme.component.html',
  styleUrls: ['add-update-scheme.component.scss']
})
export class AddUpdateSchemeComponent extends AppBaseComponent implements OnInit {

  form!: FormGroup;
  fundHouseDto: FundDto = new FundDto();
  fundHouses: FundHouseDto[] = [];
  submitted = false;
  loading = false;
  error = '';
  funds: FundDto[] = [];

  constructor(
    public dialogRef: MatDialogRef<AddUpdateSchemeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SchemeListItemModel,
    private fb: FormBuilder,
    private alertsService: AlertsService,
    private adminService: AdminService,
    private commonService: CommonService,
    localStorageService: LocalStorageService,
    activatedRoute: ActivatedRoute,
    private readonly datePipe: DatePipe
  ) {

    super(activatedRoute, localStorageService);

    dialogRef.disableClose = false;
    dialogRef.backdropClick().subscribe(_ => {
      dialogRef.close();
    })
  }

  public get isUpdate(): boolean {
    return (this.data && this.data.id != null);
  }

  async ngOnInit() {
    this.form = this.fb.group({
      id: [null],
      fundHouseId: ['', Validators.required],
      fundId: ['', Validators.required],
      shortName: ['', Validators.required],
      fullName: ['', Validators.required],
      schemeManager: [''],
      launchDate: [null],
      date1: [null],
      date2: [null],
      date3: [null],
      date4: [null],
      date5: [null],
      date6: [null]
    }
    );

    if (this.isUpdate) {
      console.log(this.data);
      this.form.controls.id.setValue(this.data.id);
      this.form.controls.fundHouseId.setValue(this.data.fundHouseId);
      this.form.controls.fundId.setValue(this.data.fundId);
      this.form.controls.shortName.setValue(this.data.shortName);
      this.form.controls.fullName.setValue(this.data.fullName);
      this.form.controls.schemeManager.setValue(this.data.schemeManager);
      this.form.controls.launchDate.setValue(this.data.launchDate);
      this.form.controls.date1.setValue(this.data.date1);
      this.form.controls.date2.setValue(this.data.date2);
      this.form.controls.date3.setValue(this.data.date3);
      this.form.controls.date4.setValue(this.data.date4);
      this.form.controls.date5.setValue(this.data.date5);
      this.form.controls.date6.setValue(this.data.date6);

      var selectedFund = new FundDto();
      selectedFund.id = this.data.fundId;
      selectedFund.shortName = this.data.fundShortName;
      selectedFund.fullName = this.data.fundFullName;

      this.funds.push(selectedFund);
    }

    this.fundHouses = this.localStorageService.fundHouses;
  }

  get formControl() {
    return this.form.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.form.valid) {

      let scheme = new AddUpdateSchemeDto();

      scheme.id = this.formControl.id.value;
      scheme.fundId = this.formControl.fundId.value;
      scheme.shortName = this.formControl.shortName.value;
      scheme.fullName = this.formControl.fullName.value;
      scheme.schemeManager = this.formControl.schemeManager.value;
      scheme.launchDate = this.formControl.launchDate.value;
      scheme.date1 = this.formControl.date1.value;
      scheme.date2 = this.formControl.date2.value;
      scheme.date3 = this.formControl.date3.value;
      scheme.date4 = this.formControl.date4.value;
      scheme.date5 = this.formControl.date5.value;
      scheme.date6 = this.formControl.date6.value;


      this.loading = true;
      this.adminService.addUpdateScheme(scheme)
        .pipe(first())
        .subscribe({
          next: (response) => {
            this.submitted = false;
            this.loading = false;
            if (response.isSuccess) {
              this.alertsService.showInfo(`Scheme ${this.isUpdate ? 'updated' : 'added'} successfully!.`, "Message", "");
              this.dialogRef.close(true);
            }
            else {
              this.alertsService.showInfo(response.message);
            }
          },
          error: error => {
            this.loading = false;
            error = error.message;
          }
        });
    }
  }
  onNoClick(): void {
    this.dialogRef.close(false);
  }

  public onFundHouseChange(e: any) {
    this.commonService.getAllFunds(e.value).then(response => {
      if (response.isSuccess) {
        this.funds = response.data;
      }
    });
  }
}
