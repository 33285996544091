import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';

@Injectable({ providedIn: 'root' })
export class FeaturePermissionService {
    constructor(private readonly localStorageService: LocalStorageService) {

    }

    public get canAddFund(): boolean {
        return this.localStorageService.isAdmin;
    }

    public get canAddScheme(): boolean {
        return this.localStorageService.isAdmin;
    }

    public get canEditFund(): boolean {
        return this.localStorageService.isAdmin;
    }

    public get canAddInvestor(): boolean {
        return this.localStorageService.isAdmin;
    }

    public get canEditInvestor(): boolean {
        return this.localStorageService.isAdmin;
    }

    public get canAddDocument(): boolean {
        return this.localStorageService.isAdmin || this.localStorageService.isFundHouseAdmin;
    }

    public get canEditDocument(): boolean {
        return this.localStorageService.isAdmin || this.localStorageService.isFundHouseAdmin;
    }

    public get canDeleteDocument(): boolean {
        return this.localStorageService.isAdmin;
    }

    public get canLinkInvestorDocument(): boolean {
        return this.localStorageService.isAdmin || this.localStorageService.isFundHouseAdmin;
    }
}