<h2 mat-dialog-title>{{isUpdate?'Update':'Add'}} Scheme Investor</h2>
<form class="form" [formGroup]="form" (ngSubmit)="onSubmit()">

    <mat-dialog-content>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Fund House</mat-label>
            <mat-select [disabled]="isUpdate" formControlName="fundHouseId"
                (selectionChange)="onFundHouseChange($event)">
                <mat-option *ngFor="let fh of fundHouses" [value]="fh.id">
                    {{fh.fullName}}
                </mat-option>
            </mat-select>
            <mat-error
                *ngIf="(formControls.fundHouseId.touched || submitted) && formControls.fundHouseId.errors?.required">
                Fund house is required
            </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Investor</mat-label>
            <mat-select [disabled]="isUpdate" formControlName="investorId">
                <mat-option *ngFor="let i of investorItems" [value]="i.id">
                    {{i.fullName}}
                </mat-option>
            </mat-select>
            <mat-error
                *ngIf="(formControls.investorId.touched || submitted) && formControls.investorId.errors?.required">
                Investor is required
            </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Fund</mat-label>
            <mat-select [disabled]="isUpdate" (selectionChange)="onFundChange($event)" formControlName="fundId">
                <mat-option *ngFor="let f of funds" [value]="f.id">
                    {{f.fullName}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="(formControls.fundId.touched || submitted) && formControls.fundId.errors?.required">
                Fund is required
            </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Scheme</mat-label>
            <mat-select [disabled]="isUpdate" formControlName="schemeId">
                <mat-option *ngFor="let s of schemes" [value]="s.value">
                    {{s.text}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="(formControls.schemeId.touched || submitted) && formControls.schemeId.errors?.required">
                Scheme is required
            </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Email 1</mat-label>
            <input [disabled]="isUpdate" type="text" matInput formControlName="email1" placeholder="Email 1">
            <mat-error *ngIf="(formControls.email1.touched || submitted) && formControls.email1.errors?.required">
                Email is required
            </mat-error>
            <mat-error *ngIf="formControls.email1.touched && formControls.email1.errors?.email">
                Enter a valid email address
            </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Email 2</mat-label>
            <input [disabled]="isUpdate" type="text" matInput formControlName="email2" placeholder="Email 2">
            <mat-error *ngIf="formControls.email2 && formControls.email2.touched && formControls.email2.errors?.email">
                Enter a valid email address
            </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Email 3</mat-label>
            <input [disabled]="isUpdate" type="text" matInput formControlName="email3" placeholder="Email 3">
            <mat-error *ngIf="formControls.email3 && formControls.email3.touched && formControls.email3.errors?.email">
                Enter a valid email address
            </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Email 4</mat-label>
            <input [disabled]="isUpdate" type="text" matInput formControlName="email4" placeholder="Email 4">
            <mat-error *ngIf="formControls.email4 && formControls.email4.touched && formControls.email4.errors?.email">
                Enter a valid email address
            </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Email 5</mat-label>
            <input [disabled]="isUpdate" type="text" matInput formControlName="email5" placeholder="Email 5">
            <mat-error *ngIf="formControls.email5 && formControls.email5.touched && formControls.email5.errors?.email">
                Enter a valid email address
            </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Folio No.</mat-label>
            <input type="text" matInput formControlName="folioNo" placeholder="folioNo">
            <mat-error *ngIf="(formControls.folioNo.touched || submitted) && formControls.folioNo.errors?.required">
                Folio No.is required
            </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <input type="text" matInput hidden="true">
            <mat-checkbox matInput formControlName="isCarryClass"> Is Carry Class?
            </mat-checkbox>
            <mat-error
                *ngIf="(formControls.isCarryClass.touched || submitted) && formControls.isCarryClass.errors?.required">Is
                Carry Class is required</mat-error>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Class</mat-label>
            <input type="text" matInput formControlName="class" placeholder="Class">
            <mat-error *ngIf="(formControls.class.touched || submitted) && formControls.class.errors?.required">Class
                is required
            </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Forfeit And Transfer Date</mat-label>
            <div class="date_container">
                <input type="text" readonly="true" matInput [matDatepicker]="forfeitAndTransferDatePicker"
                    formControlName="forfeitAndTransferDate">
                <mat-datepicker-toggle matIconSuffix [for]="forfeitAndTransferDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #forfeitAndTransferDatePicker></mat-datepicker>
            </div>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Salutation</mat-label>
            <input type="text" matInput formControlName="salutation" placeholder="Salutation">
            <mat-error
                *ngIf="(formControls.salutation.touched || submitted) && formControls.salutation.errors?.required">Salutation
                is required</mat-error>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Mode Of Holding</mat-label>
            <mat-select formControlName="modeOfHolding">
                <mat-option *ngFor="let fh of modeOfHoldings" [value]="fh">
                    {{fh}}
                </mat-option>
            </mat-select>
            <mat-error
                *ngIf="(formControls.modeOfHolding.touched || submitted) && formControls.modeOfHolding.errors?.required">
                Mode Of Holding is required
            </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Tax Pan Number</mat-label>
            <input type="text" matInput formControlName="taxPanNumber" placeholder="Tax Pan Number">
            <mat-error
                *ngIf="(formControls.taxPanNumber.touched || submitted) && formControls.taxPanNumber.errors?.required">
                Tax Pan Number is required
            </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Contact No</mat-label>
            <input type="text" matInput formControlName="contactNo" placeholder="Contact No">
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Address</mat-label>
            <input type="text" matInput formControlName="address" placeholder="Address">
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Address 1</mat-label>
            <input type="text" matInput formControlName="address1" placeholder="Address 1">
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Adress 2</mat-label>
            <input type="text" matInput formControlName="address2" placeholder="Address 2">
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>City</mat-label>
            <input type="text" matInput formControlName="city" placeholder="City">
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>State</mat-label>
            <input type="text" matInput formControlName="state" placeholder="State">
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Pin Code</mat-label>
            <input type="text" matInput formControlName="pincode" placeholder="Pin Code">
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Bank Account Type</mat-label>
            <mat-select formControlName="bankAccountType">
                <mat-option *ngFor="let fh of bankAccountTypes" [value]="fh">
                    {{fh}}
                </mat-option>
            </mat-select> <mat-error
                *ngIf="(formControls.bankAccountType.touched || submitted) && formControls.bankAccountType.errors?.required">
                Bank Account Type is required</mat-error>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Bank Name</mat-label>
            <input type="text" matInput formControlName="bankName" placeholder="Bank Name"><mat-error
                *ngIf="(formControls.bankName.touched || submitted) && formControls.bankName.errors?.required">Bank
                Name is required
            </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>IFSC Code</mat-label>
            <input type="text" matInput formControlName="ifscCode" placeholder="IFSC Code">
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>MICR Code</mat-label>
            <input type="text" matInput formControlName="micrCode" placeholder="MICR Code">
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Account Number</mat-label>
            <input type="text" matInput formControlName="accountNumber" placeholder="Account Number">
            <mat-error
                *ngIf="(formControls.accountNumber.touched || submitted) && formControls.accountNumber.errors?.required">
                Account Number is required</mat-error>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Tax Status</mat-label>
            <mat-select matInput formControlName="taxStatus">
                <mat-option *ngFor="let fh of taxOptions" [value]="fh">
                    {{fh}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="(formControls.taxStatus.touched || submitted) && formControls.taxStatus.errors?.required">
                Tax Status is required
            </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Setup Fees</mat-label>
            <input type="text" matInput formControlName="setupFees" placeholder="Setup Fees">
            <mat-error *ngIf="(formControls.setupFees.touched || submitted) && formControls.setupFees.errors?.required">
                Setup Fees is required
            </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Management Fees</mat-label>
            <input type="text" matInput formControlName="managementFees" placeholder="Management Fees">
            <mat-error
                *ngIf="(formControls.managementFees.touched || submitted) && formControls.managementFees.errors?.required">
                Management Fees is required
            </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Operating Expenses</mat-label>
            <input type="text" matInput formControlName="operatingExpenses" placeholder="Operating Expenses">
            <mat-error
                *ngIf="(formControls.operatingExpenses.touched || submitted) && formControls.operatingExpenses.errors?.required">
                Operating Expenses is required</mat-error>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>SEBI Investor Type 1</mat-label>
            <mat-select matInput formControlName="sebiInvestorType1">
                <mat-option *ngFor="let fh of sebiInvestorTypes" [value]="fh">
                    {{fh}}
                </mat-option>
            </mat-select>
            <mat-error
                *ngIf="(formControls.sebiInvestorType1.touched || submitted) && formControls.sebiInvestorType1.errors?.required">
                SEBI Investor Type 1 is required</mat-error>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>SEBI Investor Type 2</mat-label>
            <mat-select matInput formControlName="sebiInvestorType2">
                <mat-option *ngFor="let fh of sebiInvestorTypes" [value]="fh">
                    {{fh}}
                </mat-option>
            </mat-select>
            <mat-error
                *ngIf="(formControls.sebiInvestorType2.touched || submitted) && formControls.sebiInvestorType2.errors?.required">
                SEBI Investor Type 2 is required</mat-error>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>SEBI Investor Type 3</mat-label>
            <mat-select matInput formControlName="sebiInvestorType3">
                <mat-option *ngFor="let fh of sebiInvestorTypes" [value]="fh">
                    {{fh}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Capital Commitment</mat-label>
            <input type="text" matInput formControlName="capitalCommitment" placeholder="capitalCommitment">
            <mat-error
                *ngIf="(formControls.capitalCommitment.touched || submitted) && formControls.capitalCommitment.errors?.required">
                Capital Commitment is required
            </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Capital Contribution</mat-label>
            <input type="text" matInput formControlName="capitalContribution" placeholder="Capital Contribution">
            <mat-error
                *ngIf="(formControls.capitalContribution.touched || submitted) && formControls.capitalContribution.errors?.required">
                Capital Contribution is required
            </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>KPFS Record Status</mat-label>
            <mat-select matInput matInput formControlName="kpfsRecordStatus">
                <mat-option *ngFor="let fh of kpfsRecordStatuses" [value]="fh">
                    {{fh}}
                </mat-option>
            </mat-select>
            <mat-error
                *ngIf="(formControls.kpfsRecordStatus.touched || submitted) && formControls.kpfsRecordStatus.errors?.required">
                KPFS Record Status is required
            </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>KPFS Incomplete Record Remark</mat-label>
            <input type="text" matInput formControlName="kpfsIncompleteRecordRemark"
                placeholder="KPFS Incomplete Record Remark">
            <mat-error
                *ngIf="(formControls.kpfsIncompleteRecordRemark.touched || submitted) && formControls.kpfsIncompleteRecordRemark.errors?.required">
                KPFS Incomplete Record Remark is required
            </mat-error>
        </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button [disabled]="loading" type="submit" mat-raised-button color="primary"
            class="me-3 w-40 rounded-pill btn-ht">
            <span *ngIf="loading" class="spinner-border spinner-border-sm me-1"></span>
            {{isUpdate?'Update':'Submit'}}</button>
        <button type="button" mat-raised-button color="warn" class="me-3 w-40 rounded-pill btn-ht"
            (click)="onNoClick()">Close</button>
    </mat-dialog-actions>
</form>