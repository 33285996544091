import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from "@angular/router";
import { Role } from "./_models";
import { AdminService } from "./_services/admin.service";
import { CommonService } from "./_services/common.service";
import { DocumentTypeDto } from "./_services/dto/document-type.dto";
import { FundHouseDto } from "./_services/dto/fund-house.dto";
import { MasterDataDto } from "./_services/dto/master-data.dto";
import { IResponse } from "./_services/dto/response.dto";
import { LocalStorageService } from "./_services/local-storage.service";

export const documentTypesResolver: ResolveFn<IResponse<DocumentTypeDto[]>> =
    (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
        var localStorageService = inject(LocalStorageService);
        if (localStorageService.documentTypes) {
            return { isSuccess: true, data: localStorageService.documentTypes } as IResponse<DocumentTypeDto[]>;
        }
        else {
            return inject(AdminService).getDocumentTypes();
        }
    };

export const fundHousesResolver: ResolveFn<IResponse<FundHouseDto[]>> =
    (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
        var localStorageService = inject(LocalStorageService);

        if (localStorageService.fundHouses) {
            return { isSuccess: true, data: localStorageService.fundHouses } as IResponse<FundHouseDto[]>;
        }
        else {
            return inject(CommonService).getAllFundHouses();
        }
    };

export const masterDataResolver: ResolveFn<IResponse<MasterDataDto>> =
    (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
        var localStorageService = inject(LocalStorageService);

        if (localStorageService.currentUser?.role === Role.Investor) {
            return null;
        }

        if (localStorageService.masterData) {
            return { isSuccess: true, data: localStorageService.masterData } as IResponse<MasterDataDto>;
        }
        else {
            return inject(AdminService).getMasterData();
        }
    };

export const fundHouseLogoResolver: ResolveFn<IResponse<string>> =
    (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {

        return inject(CommonService).getFundHouseLogoByName(route.params['fundHouseName']);
    };