export const MY_DATE_FORMAT = {
    parse: {
        dateInput: 'DD/MM/YYYY', // this is how your date will be parsed from Input
    },
    display: {
        dateInput: 'DD/MM/YYYY', // this is how your date will get displayed on the Input
        monthYearLabel: 'MMMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY'
    }
};

export const APPLICATION_DATE_FORMAT: string = 'dd/MM/yyyy';
export const APPLICATION_DATE_TIME_FORMAT: string = 'dd/MM/yyyy hh:mm:ss';