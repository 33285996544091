<h2 mat-dialog-title>{{isUpdate?'Update':'Add'}} Investor</h2>
<form class="form" [formGroup]="form" (ngSubmit)="onSubmit()">

    <mat-dialog-content>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Fund House</mat-label>
            <mat-select [disabled]="isUpdate" formControlName="fundHouseId">
                <mat-option *ngFor="let fh of fundHouses" [value]="fh.id">
                    {{fh.fullName}}
                </mat-option>
            </mat-select>
            <mat-error
                *ngIf="(registerFormControl.fundHouseId.touched || submitted) && registerFormControl.fundHouseId.errors?.required">
                Fund house is required
            </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Full Name</mat-label>
            <input type="text" matInput formControlName="fullName" placeholder="Full Name">
            <mat-error
                *ngIf="(registerFormControl.fullName.touched || submitted) && registerFormControl.fullName.errors?.required">
                Full Name is required
            </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Identity Pan Number</mat-label>
            <input type="text" matInput formControlName="identityPanNumber" placeholder="Identity Pan Number">
            <mat-error
                *ngIf="(registerFormControl.identityPanNumber.touched || submitted) && registerFormControl.identityPanNumber.errors?.required">
                Identity Pan Number is required
            </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Identifier</mat-label>
            <input type="text" matInput formControlName="identifier" placeholder="Identifier">
            <mat-error
                *ngIf="(registerFormControl.identifier.touched || submitted) && registerFormControl.identifier.errors?.required">
                Identifier is required
            </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline" [hidden]="!isUpdate">
            <input type="text" matInput hidden="true">
            <mat-checkbox matInput formControlName="isActive"> Active
            </mat-checkbox>
            <mat-error
                *ngIf="(registerFormControl.isActive.touched || submitted) && registerFormControl.isActive.errors?.required">
                Active status is required</mat-error>
        </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button [disabled]="loading" type="submit" mat-raised-button color="primary"
            class="me-3 w-40 rounded-pill btn-ht">
            <span *ngIf="loading" class="spinner-border spinner-border-sm me-1"></span>
            {{isUpdate?'Update':'Submit'}}</button>
        <button type="button" mat-raised-button color="warn" class="me-3 w-40 rounded-pill btn-ht"
            (click)="onNoClick()">Close</button>
    </mat-dialog-actions>
</form>