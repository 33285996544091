<h2 mat-dialog-title>{{isUpdate?'Update':'Add'}} Scheme</h2>

<form class="form" [formGroup]="form" (ngSubmit)="onSubmit()">
    <mat-dialog-content>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Fund House</mat-label>
            <mat-select [disabled]="isUpdate" formControlName="fundHouseId"
                (selectionChange)="onFundHouseChange($event)">
                <mat-option *ngFor="let fh of fundHouses" [value]="fh.id">
                    {{fh.fullName}}
                </mat-option>
            </mat-select>
            <mat-error
                *ngIf="(formControl.fundHouseId.touched || submitted) && formControl.fundHouseId.errors?.required">
                Fund house is required
            </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Fund</mat-label>
            <mat-select [disabled]="isUpdate" formControlName="fundId">
                <mat-option *ngFor="let f of funds" [value]="f.id">
                    {{f.fullName}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="(formControl.fundId.touched || submitted) && formControl.fundId.errors?.required">
                Fund is required
            </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Short Name</mat-label>
            <input type="text" matInput formControlName="shortName" placeholder="Short Name">
            <mat-error *ngIf="(formControl.shortName.touched || submitted) && formControl.shortName.errors?.required">
                Short Name is required
            </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Full Name</mat-label>
            <input type="text" matInput formControlName="fullName" placeholder="Full Name">
            <mat-error *ngIf="(formControl.fullName.touched || submitted) && formControl.fullName.errors?.required">
                Full Name is required
            </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Scheme Manager</mat-label>
            <input type="text" matInput formControlName="schemeManager" placeholder="Scheme Manager">
        </mat-form-field>
        <mat-form-field class="date-mat-field" appearance="outline">
            <mat-label>Launch Date</mat-label>
            <div class="date_container">
                <input type="text" readonly="true" matInput [matDatepicker]="pickerLaunchDate"
                    formControlName="launchDate">
                <mat-datepicker-toggle matIconSuffix [for]="pickerLaunchDate"></mat-datepicker-toggle>
                <mat-datepicker #pickerLaunchDate></mat-datepicker>
            </div>
        </mat-form-field>
        <mat-form-field class="date-mat-field" appearance="outline">
            <mat-label>Date1</mat-label>
            <div class="date_container">
                <input type="text" readonly="true" matInput [matDatepicker]="pickerDate1" formControlName="date1">
                <mat-datepicker-toggle matIconSuffix [for]="pickerDate1"></mat-datepicker-toggle>
                <mat-datepicker #pickerDate1></mat-datepicker>
            </div>
        </mat-form-field>
        <mat-form-field class="date-mat-field" appearance="outline">
            <mat-label>Date2</mat-label>
            <div class="date_container">
                <input type="text" readonly="true" matInput [matDatepicker]="pickerDate2" formControlName="date2">
                <mat-datepicker-toggle matIconSuffix [for]="pickerDate2"></mat-datepicker-toggle>
                <mat-datepicker #pickerDate2></mat-datepicker>
            </div>
        </mat-form-field>
        <mat-form-field class="date-mat-field" appearance="outline">
            <mat-label>Date3</mat-label>
            <div class="date_container">
                <input type="text" readonly="true" matInput [matDatepicker]="pickerDate3" formControlName="date3">
                <mat-datepicker-toggle matIconSuffix [for]="pickerDate3"></mat-datepicker-toggle>
                <mat-datepicker #pickerDate3></mat-datepicker>
            </div>
        </mat-form-field>
        <mat-form-field class="date-mat-field" appearance="outline">
            <mat-label>Date4</mat-label>
            <div class="date_container">
                <input type="text" readonly="true" matInput [matDatepicker]="pickerDate4" formControlName="date4">
                <mat-datepicker-toggle matIconSuffix [for]="pickerDate4"></mat-datepicker-toggle>
                <mat-datepicker #pickerDate4></mat-datepicker>
            </div>
        </mat-form-field>
        <mat-form-field class="date-mat-field" appearance="outline">
            <mat-label>Date5</mat-label>
            <div class="date_container">
                <input type="text" readonly="true" matInput [matDatepicker]="pickerDate5" formControlName="date5">
                <mat-datepicker-toggle matIconSuffix [for]="pickerDate5"></mat-datepicker-toggle>
                <mat-datepicker #pickerDate5></mat-datepicker>
            </div>
        </mat-form-field>
        <mat-form-field class="date-mat-field" appearance="outline">
            <mat-label>Date6</mat-label>
            <div class="date_container">
                <input type="text" readonly="true" matInput [matDatepicker]="pickerDate6" formControlName="date6">
                <mat-datepicker-toggle matIconSuffix [for]="pickerDate6"></mat-datepicker-toggle>
                <mat-datepicker #pickerDate6></mat-datepicker>
            </div>
        </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button [disabled]="loading" type="submit" mat-raised-button color="primary"
            class="me-3 w-40 rounded-pill btn-ht">
            <span *ngIf="loading" class="spinner-border spinner-border-sm me-1"></span>
            {{isUpdate?'Update':'Submit'}}</button>
        <button type="button" mat-raised-button color="warn" class="me-3 w-40 rounded-pill btn-ht"
            (click)="onNoClick()">Close</button></mat-dialog-actions>
</form>