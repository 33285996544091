import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';

import { IResponse } from './dto/response.dto';

import { ApiService } from './api.service';
import { DocumentTypeDto } from './dto/document-type.dto';
import { DropdownItemDto } from './dto/drop-down-item.dto';
import { FundDto } from './dto/fund.dto';
import { MasterDataDto } from './dto/master-data.dto';
import { AddUpdateSchemeInvestorDto, InvestorDto, SchemeInvestorDto } from './dto/registration.dto';
import { AddUpdateSchemeDto } from './dto/scheme.dto';
import { FundAdminListItemDto, FundHouseAdminDto } from './dto/user.dto';

@Injectable({ providedIn: 'root' })
export class AdminService {
    constructor(private http: HttpClient, private readonly apiService: ApiService,) {
    }

    public async getAllFundHouseAdmins(): Promise<IResponse<FundHouseAdminDto[]>> {
        return await this.apiService.get<IResponse<FundHouseAdminDto[]>>("/admin/fund-house-admin/list");
    }

    public async getAllFundAdmins(): Promise<IResponse<FundAdminListItemDto[]>> {
        return await this.apiService.get<IResponse<FundAdminListItemDto[]>>("/admin/fund-admin/list");
    }

    public async getAllInvestors(): Promise<IResponse<InvestorDto[]>> {
        return await this.apiService.get<IResponse<InvestorDto[]>>("/admincommon/investor/list");
    }

    public async getSchemeInvestors(): Promise<IResponse<SchemeInvestorDto[]>> {
        return await this.apiService.get<IResponse<SchemeInvestorDto[]>>("/admincommon/scheme-investor/list");
    }

    public async getInvestorDropdownItems(fundHouseId?: number): Promise<IResponse<InvestorDto[]>> {
        if (fundHouseId) {
            return await this.apiService.get<IResponse<InvestorDto[]>>("/admincommon/investor/drop-down-item/list?fundHouseId=" + fundHouseId);

        }
        return await this.apiService.get<IResponse<InvestorDto[]>>("/admincommon/investor/drop-down-item/list");
    }

    public async getSchemeDropdownItems(fundHouseId: number, fundId?: number): Promise<IResponse<DropdownItemDto[]>> {
        let apiUrl = '/admincommon/scheme/drop-down-item/list?fundHouseId=' + fundHouseId;
        if (fundId) {
            apiUrl += '&fundId=' + fundId;
        }
        return await this.apiService.get<IResponse<DropdownItemDto[]>>(apiUrl);
    }

    public async getDocumentTypes(): Promise<IResponse<DocumentTypeDto[]>> {
        return await this.apiService.get<IResponse<DocumentTypeDto[]>>("/masterdata/documenttypes");
    }

    addUpdateFundHouse(dto: FormData) {
        return this.http.post<IResponse<boolean>>(`${environment.apiUrl}/admin/fund-house/add-update`, dto)
            .pipe(map((response) => {
                return response;
            }));
    }

    addUpdateFund(dto: FundDto) {
        return this.http.post<IResponse<boolean>>(`${environment.apiUrl}/admin/fund/add-update`, dto)
            .pipe(map((response) => {
                return response;
            }));
    }

    addUpdateScheme(dto: AddUpdateSchemeDto) {
        return this.http.post<IResponse<boolean>>(`${environment.apiUrl}/admin/scheme/add-update`, dto)
            .pipe(map((response) => {
                return response;
            }));
    }

    addUpdateDocument(dto: FormData) {
        return this.http.post<IResponse<boolean>>(`${environment.apiUrl}/document/add-update`, dto)
            .pipe(map((response) => {
                return response;
            }));
    }

    async linkInvestorDocument(dto: FormData) {
        return await this.apiService.getDownloadableDocumentWithPost(`/admincommon/investor-document/link`, dto);
        // return this.http.post<IResponse<Blob>>(`${environment.apiUrl}/admincommon/investor-document/link`, dto)
        //     .pipe(map((response) => {
        //         return response;
        //     }));
    }

    public async getMasterData(): Promise<IResponse<MasterDataDto>> {
        return await this.apiService.get<IResponse<MasterDataDto>>("/masterdata/data");
    }

    addSchemeInvestor(dto: AddUpdateSchemeInvestorDto) {
        return this.http.post<IResponse<boolean>>(`${environment.apiUrl}/admin/scheme-investor/add`, dto)
            .pipe(map((response) => {
                return response;
            }));
    }

    updateSchemeInvestor(dto: AddUpdateSchemeInvestorDto) {
        return this.http.post<IResponse<boolean>>(`${environment.apiUrl}/admin/scheme-investor/update`, dto)
            .pipe(map((response) => {
                return response;
            }));
    }
}