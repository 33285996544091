import { ModelBase } from "./model-base";

export class FundListItemModel extends ModelBase {
  public shortName: string = '';
  public fullName: string = '';
  public sebiRegistrationNumber: string = '';
  public investmentManagerName: string = '';
  public sponserName: string = '';
  public merchantBankerName: string = '';
  public legalAdvisorName: string = '';
  public fundHouseName: string = '';
  public fundHouseId: number = null;
}