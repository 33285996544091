import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { DocumentListItemDto } from './dto/document.dto';
import { FundHouseDto } from './dto/fund-house.dto';
import { FundDto } from './dto/fund.dto';
import { IResponse } from './dto/response.dto';
import { SchemeDto } from './dto/scheme.dto';

@Injectable({ providedIn: 'root' })
export class CommonService {
    constructor(private http: HttpClient, private readonly apiService: ApiService,) {
    }

    public async getDocuments(documentTypeId: number = null): Promise<IResponse<DocumentListItemDto[]>> {
        if (documentTypeId) {
            return await this.apiService.get<IResponse<DocumentListItemDto[]>>("/document/document/list?documentTypeId=" + documentTypeId);
        }
        return await this.apiService.get<IResponse<DocumentListItemDto[]>>("/document/document/list");
    }

    public async downloadDocument(documentId: number): Promise<Observable<Blob>> {
        return await this.apiService.getDownloadableDocument(`/document/document/${documentId}/download`);
    }

    public async deleteDocument(documentId: number): Promise<IResponse<string>> {
        return await this.apiService.delete<IResponse<string>>(`/document/document/${documentId}/delete`);
    }

    public async getAllFunds(fundHouseId: number = null): Promise<IResponse<FundDto[]>> {
        if (fundHouseId) {
            return await this.apiService.get<IResponse<FundDto[]>>("/common/fund/list?fundHouseId=" + fundHouseId);
        }
        return await this.apiService.get<IResponse<FundDto[]>>("/common/fund/list");
    }

    public async getAllSchemes(fundId: number = null): Promise<IResponse<SchemeDto[]>> {
        if (fundId) {
            return await this.apiService.get<IResponse<SchemeDto[]>>("/common/scheme/list?fundId=" + fundId);
        }
        return await this.apiService.get<IResponse<SchemeDto[]>>("/common/scheme/list");
    }

    public async getAllFundHouses(): Promise<IResponse<FundHouseDto[]>> {
        return await this.apiService.get<IResponse<FundHouseDto[]>>("/common/fund-house/list");
    }

    public async getFundHouseByName(fundHouseName: string): Promise<IResponse<FundHouseDto>> {
        return await this.apiService.get<IResponse<FundHouseDto>>("/common/fund-house/" + fundHouseName);
    }

    public async getFundHouseLogoByName(fundHouseName: string): Promise<IResponse<string>> {
        return await this.apiService.get<IResponse<string>>(`/common/fund-house/${fundHouseName}/logo`);
    }

    public async getLatestUploadedDocumentTypeId(): Promise<IResponse<number>> {

        return await this.apiService.get<IResponse<number>>("/common/document/latesttype");
    }
}