<h2 matDialogTitle>{{title}}</h2>
<mat-dialog-content>
  <div style="display: flex;">
    <mat-icon style="margin-right:0.25em;">
      {{isError?"error":"check"}}
    </mat-icon>
    {{message}}
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button color="primary" matDialogClose>Ok</button>
</mat-dialog-actions>