<h2 mat-dialog-title>{{isUpdate?'Update':'Add'}} Fund House Admin</h2>

<form class="form" [formGroup]="form" (ngSubmit)="onSubmit()">
    <mat-dialog-content>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Fund House</mat-label>
            <mat-select [disabled]="isUpdate" formControlName="fundHouseId">
                <mat-option *ngFor="let fh of fundHouses" [value]="fh.id">
                    {{fh.fullName}}
                </mat-option>
            </mat-select>
            <mat-error
                *ngIf="(registerFormControl.fundHouseId.touched || submitted) && registerFormControl.fundHouseId.errors?.required">
                Fund house is required
            </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>First Name</mat-label>
            <input type="text" matInput formControlName="fullName" placeholder="First Name">
            <mat-error
                *ngIf="(registerFormControl.fullName.touched || submitted) && registerFormControl.fullName.errors?.required">
                Full Name is required
            </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline" *ngIf="!isUpdate">
            <mat-label>Email</mat-label>
            <input [disabled]="isUpdate" type="text" matInput formControlName="email" placeholder="Email">
            <mat-error
                *ngIf="(registerFormControl.email.touched || submitted) && registerFormControl.email.errors?.required">
                Email is required
            </mat-error>
            <mat-error *ngIf="registerFormControl.email.touched && registerFormControl.email.errors?.email">
                Enter a valid email address
            </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline" *ngIf="isUpdate">
            <input type="text" matInput hidden="true">
            <mat-checkbox matInput formControlName="isActive"> Active
            </mat-checkbox>
        </mat-form-field>
        <div class="my-3 text-center">

        </div>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button [disabled]="loading" type="submit" mat-raised-button color="primary"
            class="me-3 w-40 rounded-pill btn-ht">
            <span *ngIf="loading" class="spinner-border spinner-border-sm me-1"></span>
            {{isUpdate?'Update':'Submit'}}</button>
        <button type="button" mat-raised-button color="warn" class="me-3 w-40 rounded-pill btn-ht"
            (click)="onNoClick()">Close</button>
    </mat-dialog-actions>
</form>